import {
    Box,
    Checkbox,
    FormControlLabel,
    TextField,
    Typography,
    useTheme,
} from '@mui/material';

import { ReactHookFormTextField } from 'dg-web-shared/common/components/material-ui/react-hook-form-fields/ReactHookFormTextField';
import {
    Localized,
    useLanguage,
} from 'dg-web-shared/common/hooks/LanguageProvider';
import { useEasyForm } from 'dg-web-shared/common/utils/FormHooksUtils';
import {
    RequestStatus,
    ServerRequestState,
    useNavigateOnError,
    useServerSuccessEffect,
} from 'dg-web-shared/lib/hooks/ServerStateHooks';
import { useParkingaboServerWrite } from '../../api/ParkingaboApi';
import { ParkingaboHeader } from '../../components/layout/ParkingaboHeader';
import { ParkingaboLayout } from '../../components/layout/ParkingaboLayout';
import { ParkingaboLink } from '../../components/ParkinaboLink';
import {
    computePasswordStrength,
    PasswordStrengthIndicator,
} from '../../components/PasswordStrengthIndicator';
import { Outlet, useNavigate } from 'react-router-dom';
import { envIsProduction } from 'dg-web-shared/lib/Environment';
import { ParkingaboButton } from '../../components/layout/ParkingaboButton';
import { Legal } from 'dg-web-shared/lib/legal/legal_module_imports';
import { useEffect, useState } from 'react';
import { GenericFormSubmitError } from '../../shared/GenericFormSubmitError.tsx';
import { ValidationData } from 'dg-web-shared/lib/forms/FormValidationHelpers.tsx';
import { useParamOrNull } from 'dg-web-shared/lib/ReactRouterHelpers.ts';
import { usePublicBadgeValidationState } from './ParkingaboBadgeValidationRoute.tsx';
import {
    LoadingSpinnerPresets,
    PresetLoadingSpinner,
} from 'dg-web-shared/common/components/material-ui/PresetLoadingSpinner.tsx';

enum Fields {
    Email = 'email',
    Password = 'password',
    Language = 'language',
}

interface RegisterPayload {
    [Fields.Email]: string;
    [Fields.Password]: string;
    [Fields.Language]: string;
}

interface RegisterResponse {
    confirmUrl?: string;
}

export function ParkingaboRegistrationRoute() {
    const [submitState, submit] = useParkingaboServerWrite<
        RegisterPayload,
        RegisterResponse
    >(() => ({
        url: '/ui-api/parkingabo/user',
    }));

    return (
        <ParkingaboRegistrationForm
            submitState={submitState}
            onSubmit={submit}
            basePath={'/register'}
        />
    );
}

interface RegisterWithBadgePayload extends RegisterPayload {
    badgeLabelNr: string;
}

export function ParkingaboRegistrationWithBadge() {
    const badgeLabelNr = useParamOrNull('badgeLabelNr');
    const navigate = useNavigate();
    const [badgeValidationState] = usePublicBadgeValidationState(badgeLabelNr);
    const [submitState, submit] = useParkingaboServerWrite<
        RegisterWithBadgePayload,
        RegisterResponse
    >(() => ({
        url: '/ui-api/parkingabo/user/with-badge',
    }));

    useServerSuccessEffect(badgeValidationState, data => {
        if (data.isOnboardingComplete || data.hasLogin) {
            navigate('/login');
        }
    });
    useNavigateOnError(badgeValidationState, '/login');

    if (badgeLabelNr === null) {
        navigate('/login');
        return null;
    }

    if (badgeValidationState.status === RequestStatus.PENDING) {
        return (
            <PresetLoadingSpinner
                preset={LoadingSpinnerPresets.FillAllSpaceAndCenter}
            />
        );
    }

    return (
        <ParkingaboRegistrationForm
            submitState={submitState}
            onSubmit={(data: RegisterPayload) => {
                submit({
                    [Fields.Email]: data[Fields.Email],
                    [Fields.Language]: data[Fields.Language],
                    [Fields.Password]: data[Fields.Password],
                    badgeLabelNr: badgeLabelNr,
                });
            }}
            basePath={`/register-with-badge/${badgeLabelNr}`}
        />
    );
}

function ParkingaboRegistrationForm({
    submitState,
    onSubmit,
    basePath,
}: {
    submitState: ServerRequestState<RegisterResponse, ValidationData>;
    onSubmit: (data: RegisterPayload) => void;
    basePath: string;
}) {
    const navigate = useNavigate();
    const theme = useTheme();
    const { language } = useLanguage();
    const [repeatPassword, setRepeatPassword] = useState('');
    const [accepted, setAccepted] = useState(false);

    const {
        formInfo: { control, watch, handleSubmit, setValue },
        genericSubmitError,
    } = useEasyForm(undefined, submitState, language, {
        defaultValues: {
            [Fields.Email]: '',
            [Fields.Password]: '',
            [Fields.Language]: language,
        },
    });

    useEffect(() => {
        setValue(Fields.Language, language);
    }, [language]);

    useServerSuccessEffect(submitState, data => {
        const confirmUrl =
            !envIsProduction() && data?.confirmUrl
                ? '?confirmUrl=' + encodeURI(data.confirmUrl)
                : '';
        const baseUrl = `${basePath}/email-sent` + confirmUrl;
        navigate(baseUrl, { replace: true });
    });

    const password = watch(Fields.Password);
    const email = watch(Fields.Email);
    const passwordStrength = computePasswordStrength(password);

    return (
        <form
            onSubmit={handleSubmit((d: RegisterPayload) => onSubmit(d))}
            style={{ height: '100%' }}
        >
            <ParkingaboLayout>
                <Box>
                    <ParkingaboHeader
                        to={'/login'}
                        linkLabel={
                            <Localized
                                de="Zurück"
                                fr="Retourner"
                                it="Indietro"
                                en="Back"
                            />
                        }
                    />
                    <Typography
                        variant="h1"
                        style={{
                            marginTop: theme.spacing(3),
                        }}
                    >
                        <Localized
                            de="Registrierung"
                            fr="Inscription"
                            it="Registrazione"
                            en="Registration"
                        />
                    </Typography>
                    <Typography
                        variant="h3"
                        style={{
                            marginBottom: theme.spacing(5),
                            marginTop: theme.spacing(2),
                        }}
                    >
                        <Localized
                            de="Zugriffdaten erfassen"
                            fr="Saisir les données d'accès"
                            it="Inserire i dati d'accesso"
                            en="Enter your access data"
                        />
                    </Typography>
                </Box>
                <Box>
                    <ReactHookFormTextField
                        name={Fields.Email}
                        required={true}
                        control={control}
                        trimOnBlur={true}
                        type="email"
                        label={
                            <Localized
                                de="E-Mail"
                                fr="E-mail"
                                it="E-mail"
                                en="Email"
                            />
                        }
                    />
                    <ReactHookFormTextField
                        label={
                            <Localized
                                de="Passwort"
                                fr="Mot de passe"
                                it="Password"
                                en="Password"
                            />
                        }
                        control={control}
                        required={true}
                        name={Fields.Password}
                        type="password"
                        margin="normal"
                    />
                    <TextField
                        label={
                            <Localized
                                de="Passwort bestätigung"
                                fr="Confirmer le mot de passe"
                                it="Conferma password"
                                en="Repeat password"
                            />
                        }
                        value={repeatPassword}
                        type="password"
                        onChange={e => setRepeatPassword(e.target.value)}
                        margin="normal"
                    />
                    {repeatPassword.length > 0 &&
                        password !== repeatPassword && (
                            <Typography color="error">
                                <Localized
                                    de="Die zwei Passwörter stimmen nicht überein"
                                    fr="Les deux mots de passe ne correspondent pas"
                                    it="Le due password non corrispondono"
                                    en="The two passwords do not match"
                                />
                            </Typography>
                        )}
                    <PasswordStrengthIndicator
                        passwordStrength={passwordStrength.strength}
                    />
                    <GenericFormSubmitError
                        error={genericSubmitError}
                        submitState={submitState}
                    />
                </Box>
                <Box>
                    <Typography
                        sx={{
                            color: theme => theme.palette.success.dark,
                            fontWeight: 'bold',
                            marginBottom: 3,
                        }}
                    >
                        {window.location.host.indexOf('cardcenter.') > -1 && (
                            <Localized
                                de="Mit Ihrer Registrierung bestätigen Sie, dass Sie ein Mitarbeiter von UBS Card Center / UMB sind."
                                fr="En vous inscrivant, vous confirmez que vous êtes un employé de UBS Card Center / UMB."
                                it="Effettuando la registrazione conferma di essere un collaboratore UBS Card Center / UMB."
                                en="By registering, you confirm that you are an employee of UBS Card Center / UMB."
                            />
                        )}
                    </Typography>
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={accepted}
                                onChange={() => setAccepted(a => !a)}
                                color="primary"
                            />
                        }
                        label={
                            <Typography color="primary">
                                <Localized
                                    de="Ich bin mit den "
                                    fr="J’accepte les "
                                    it="Sono d'accordo con le "
                                    en="I agree with the "
                                />
                                <ParkingaboLink
                                    to={Legal.deriveParkingaboTos()[language]}
                                    target={'_blank'}
                                    underline="always"
                                >
                                    <Localized
                                        de="AGB"
                                        fr="CGV"
                                        it="CG"
                                        en="terms and conditions"
                                    />
                                </ParkingaboLink>
                                <Localized
                                    de=" und der "
                                    fr=" et la "
                                    it=" e con "
                                    en=" and the "
                                />
                                <ParkingaboLink
                                    to={
                                        Legal.deriveParkingaboPrivacy()[
                                            language
                                        ]
                                    }
                                    target={'_blank'}
                                    underline="always"
                                >
                                    <Localized
                                        de="Datenschutzerklärung"
                                        fr="déclaration sur la protection des données"
                                        it="l'informativa sulla protezione dei dati"
                                        en="privacy policy"
                                    />
                                </ParkingaboLink>
                                <Localized
                                    de=" einverstanden."
                                    fr="."
                                    it="."
                                    en="."
                                />
                            </Typography>
                        }
                    />
                    <ParkingaboButton
                        variant="contained"
                        color="primary"
                        type="submit"
                        disabled={
                            email.length <= 0 ||
                            password.length <= 0 ||
                            password !== repeatPassword ||
                            !accepted
                        }
                        style={{
                            marginTop: theme.spacing(3),
                            width: '50%',
                            float: 'right',
                        }}
                        loading={submitState.status === RequestStatus.PENDING}
                    >
                        <Localized
                            de="Bestätigen"
                            fr="Confirmer"
                            it="Conferma"
                            en="Confirm"
                        />
                    </ParkingaboButton>
                </Box>

                <Outlet />
            </ParkingaboLayout>
        </form>
    );
}
