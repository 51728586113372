import { Localized } from 'dg-web-shared/common/hooks/LanguageProvider';
import {
    PaymentMethodForm,
    PaymentMethodType,
} from '../../../components/forms/PaymentMethodForm';
import { ParkingaboLayoutWithHeader } from '../../../components/layout/ParkingaboLayoutWithHeader';
import { useParkingaboAuthedPathGeneration } from '../../RouteUtils';
import { ParkingaboRoutedModal } from '../../../components/layout/ParkingaboRoutedModal';

export function ReplacePaymentRoute() {
    const generateAuthedParkingaboPath = useParkingaboAuthedPathGeneration();

    return (
        <ParkingaboRoutedModal
            open={true}
            backUrl={generateAuthedParkingaboPath('settings/payment')}
            render={() => (
                <ParkingaboLayoutWithHeader
                    noGutter
                    backTo={generateAuthedParkingaboPath('settings/payment')}
                    title={
                        <Localized
                            de="Zahlungsmittel ersetzen"
                            fr="Remplacer les moyens de paiement"
                            it="Sostituire i mezzi di pagamento"
                            en="Replace payment mean"
                        />
                    }
                >
                    <PaymentMethodForm
                        paymentMethodType={PaymentMethodType.REGISTER}
                    />
                </ParkingaboLayoutWithHeader>
            )}
        />
    );
}
