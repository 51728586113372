import { Localized } from 'dg-web-shared/common/hooks/LanguageProvider';
import { LicensePlateCountry } from 'dg-web-shared/dto/LicensePlateCountry';
import {
    useParkingaboServerFetch,
    useParkingaboServerWrite,
} from '../../../api/ParkingaboApi';
import {
    VehicleForm,
    VehicleOnboardingPayload,
} from '../../../components/forms/VehicleForm';
import { ParkingaboAsyncLoadedSection } from '../../../components/layout/ParkingaboAsyncLoadedSection';
import { AuthedRouteCompProps } from '../../RouteUtils';
import {
    useNavigateOnSuccess,
    useServerSuccessEffect,
} from 'dg-web-shared/lib/hooks/ServerStateHooks';
import { ParkingaboLayoutWithHeader } from '../../../components/layout/ParkingaboLayoutWithHeader';
import { ParkingaboRoutedModal } from '../../../components/layout/ParkingaboRoutedModal';
import { makeCountryOptions } from '../../../hooks/UseCountryFetch.tsx';

export function AddVehicleRoute({ vehicles }: AuthedRouteCompProps) {
    const [countriesState] = useParkingaboServerFetch<LicensePlateCountry[]>(
        () => ({
            url: `/ui-api/meta/license-plate-countries`,
        }),
        {},
    );

    const [state, submit] = useParkingaboServerWrite<
        VehicleOnboardingPayload,
        string
    >(() => ({
        url: '/ui-api/parkingabo/user/self/vehicles',
    }));

    useServerSuccessEffect(state, vehicles.refetch);
    useNavigateOnSuccess(state, '..');

    return (
        <ParkingaboRoutedModal
            open={true}
            backUrl={'..'}
            render={() => (
                <ParkingaboLayoutWithHeader
                    backTo={'..'}
                    title={
                        <Localized
                            de="Fahrzeug hinzufügen"
                            fr="Ajouter un véhicule"
                            it="Aggiungi veicolo"
                            en="Add vehicle"
                        />
                    }
                >
                    <ParkingaboAsyncLoadedSection
                        state={countriesState}
                        render={data => {
                            return (
                                <VehicleForm
                                    countriesOptions={makeCountryOptions(data)}
                                    requestState={state}
                                    submit={submit}
                                />
                            );
                        }}
                    />
                </ParkingaboLayoutWithHeader>
            )}
        />
    );
}
