import { Localized } from 'dg-web-shared/common/hooks/LanguageProvider';
import {
    AuthedRouteCompProps,
    useParkingaboAuthedPathGeneration,
} from '../../RouteUtils';
import { useParkingaboServerWrite } from '../../../api/ParkingaboApi';
import {
    RequestMethod,
    RequestStatus,
    useServerSuccessEffect,
} from 'dg-web-shared/lib/hooks/ServerStateHooks';
import {
    LoadingSpinnerPresets,
    PresetLoadingSpinner,
} from 'dg-web-shared/common/components/material-ui/PresetLoadingSpinner';
import { FeedbackPopup } from '../../../components/FeedbackPopup';
import { useNavigate } from 'react-router-dom';
import { AliasInvalidReason } from '../../../shared/ParkingaboModels';

export function OnboardingSuccessRoute({
    user,
    refetchUser,
}: AuthedRouteCompProps) {
    const [onboardingCompleteState, setOnboardingComplete] =
        useParkingaboServerWrite<null, { success: boolean }>(() => ({
            url: '/ui-api/parkingabo/user/self/set-onboarding-complete',
            method: RequestMethod.POST,
        }));
    const navigate = useNavigate();
    const generateAuthedParkingaboPath = useParkingaboAuthedPathGeneration();

    useServerSuccessEffect(onboardingCompleteState, () => {
        refetchUser();
    });

    if (onboardingCompleteState.status === RequestStatus.NEVER_EXECUTED) {
        setOnboardingComplete(null);
    } else if (
        onboardingCompleteState.status === RequestStatus.PENDING ||
        user.aliasInvalidReason === AliasInvalidReason.ONBOARDING_REQUIRED
    ) {
        return (
            <PresetLoadingSpinner
                preset={LoadingSpinnerPresets.FillAllSpaceAndCenter}
            />
        );
    }

    return (
        <FeedbackPopup
            open={true}
            color="success"
            onAbort={() => {
                navigate(generateAuthedParkingaboPath(''));
            }}
            abortLabel={'OK'}
            title={
                <Localized
                    de="Bestätigung"
                    fr="Confirmation"
                    it="Conferma"
                    en="Confirmation"
                />
            }
        >
            <p>
                <Localized
                    de="Die Konfiguration wurde erfolgreich abgeschlossen."
                    fr="La configuration a été complétée avec succès."
                    it="La configurazione è stata conclusa con successo."
                    en="The Setup was successfully completed."
                />
            </p>
            <p>
                <Localized
                    de="Die eingegebenen Informationen können jederzeit in Ihrem Parkingabo-Konto geändert werden."
                    fr="Les informations saisies peuvent être modifiées à tout moment dans votre compte Parkingabo."
                    it="Le informazioni inserite possono essere modificate in qualsiasi momento nel suo conto Parkingabo."
                    en="The information entered can be changed at any time in your Parkingabo account."
                />
            </p>
        </FeedbackPopup>
    );
}
