import { useEffect, useState } from 'react';
import { Outlet, useNavigate } from 'react-router-dom';
import {
    LoadingSpinnerPresets,
    PresetLoadingSpinner,
} from 'dg-web-shared/common/components/material-ui/PresetLoadingSpinner';
import {
    AuthedRouteCompProps,
    useParkingaboAuthedPathGeneration,
} from '../routes/RouteUtils';

import { CustomerTenantState } from '../shared/ParkingaboModels';

export function ProductsAccessChecker({ user }: AuthedRouteCompProps) {
    const navigate = useNavigate();
    const generateAuthedParkingaboPath = useParkingaboAuthedPathGeneration();
    const [routeChecked, setRouteChecked] = useState(false);

    useEffect(() => {
        switch (user.customerState) {
            case CustomerTenantState.ACTIVE:
                break;
            case CustomerTenantState.LOCKED:
                navigate(generateAuthedParkingaboPath('locked-account'));
                break;
            case CustomerTenantState.ARCHIVED:
                throw new Error('Archived user tried to access products');
        }
        setRouteChecked(true);
    }, [user.customerState]);

    if (!routeChecked) {
        return (
            <PresetLoadingSpinner
                preset={LoadingSpinnerPresets.FillAllSpaceAndCenter}
            />
        );
    } else {
        return <Outlet />;
    }
}
