import { Message } from 'dg-web-shared/lib/Localized';
import {
    ParkingaboProductType,
    ParkingaboZoneInfo,
} from '../../../shared/ParkingaboProductModels';
import { VehicleLicensePlateType } from 'dg-web-shared/common/models/Vehicle';
import { SubscriptionIntervalDuration } from 'product-shared/product-template/SubscriptionIntervalDuration';

export type ParkingaboProductTemplate =
    | ParkingaboProductTemplateReservation
    | ParkingaboProductTemplateSubscription
    | ParkingaboProductTemplateGuestReservation;

interface ParkingaboProductTemplateBase {
    type: ParkingaboProductType;
    contractTemplateId: number;
    name: Message;
    vehicleType: VehicleLicensePlateType;
    zoneIds: number[];
    info?: Message;
}

export enum ReservationTimeAndPriceConfigType {
    CALENDAR_DAY = 'CALENDAR_DAY',
}

export interface WeekdayPrice {
    mondayRappen: number | null;
    tuesdayRappen: number | null;
    wednesdayRappen: number | null;
    thursdayRappen: number | null;
    fridayRappen: number | null;
    saturdayRappen: number | null;
    sundayRappen: number | null;
}

export interface ReservationTimeACalendarDayReservationTimeAndPrice {
    type: ReservationTimeAndPriceConfigType.CALENDAR_DAY;
    validityStart: string;
    validityEnd: string;
    maxPurchaseQuantity: number;
    maxDaysPurchaseAheadInFuture: number;
    minValidityStart: string | null;
    priceConfig: WeekdayPrice;
}

export type DayAndPriceConfig =
    ReservationTimeACalendarDayReservationTimeAndPrice;

export interface ParkingaboProductTemplateReservation
    extends ParkingaboProductTemplateBase {
    type: ParkingaboProductType.RESERVATION;
    dayAndPriceConfig: DayAndPriceConfig;
}

export interface ParkingaboProductTemplateGuestReservation
    extends ParkingaboProductTemplateBase {
    type: ParkingaboProductType.GUEST_RESERVATION;
    appPurchaseDisclaimerText: Message;
    appPurchaseCustomerRemarkLabel: Message | null;
    maxHours: number;
    maxDaysPurchaseAheadInFuture: number;
    minValidityStart: string | null;
}

export interface ParkingaboProductTemplateSubscription
    extends ParkingaboProductTemplateBase {
    type: ParkingaboProductType.SUBSCRIPTION;
    intervalConfig: SubscriptionIntervalConfig;
}

export function formatZoneName(zone: ParkingaboZoneInfo): string {
    return [zone.name, zone.city].join(' – ');
}

export interface SubscriptionIntervalConfig {
    maxIntervalsPurchaseAheadInFuture: number;
    intervalPriceRappen: number;
    intervalDuration: SubscriptionIntervalDuration;
}
