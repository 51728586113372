import {
    ReactNode,
    createContext,
    useContext,
    useEffect,
    useState,
    FC,
} from 'react';
import {
    FetchInfo,
    RequestConfig,
    RequestStatus,
    ServerFetchState,
    ServerRequestState,
    ServerWriteState,
    useServerFetch,
    useServerWrite,
    WriteInfo,
} from 'dg-web-shared/lib/hooks/ServerStateHooks';
import { ValidationData } from 'dg-web-shared/lib/forms/FormValidationHelpers';

const ApiMiddlewareContext = createContext({
    csrfToken: null as string | null,
    setCsrfToken: (_: string | null) => {},
});

export const ParkingaboApiMiddlewereContextProvider: FC<{
    children: ReactNode;
}> = ({ children }) => {
    const [csrfToken, setCsrfToken] = useState<string | null>(null);

    return (
        <ApiMiddlewareContext.Provider value={{ csrfToken, setCsrfToken }}>
            {children}
        </ApiMiddlewareContext.Provider>
    );
};

export const useUpdateParkingaboCsrfToken = () => {
    const { setCsrfToken } = useContext(ApiMiddlewareContext);

    return setCsrfToken;
};

export const useParkingaboCsrfToken = () => {
    const { csrfToken } = useContext(ApiMiddlewareContext);

    return csrfToken;
};

function wrapRequestFactory<C>(
    originalFactory: FetchInfo<C>,
    csrfToken: string | null,
): (c: C) => RequestConfig {
    return c => {
        const requestInfo = originalFactory(c);
        const headers = requestInfo.headers ?? {};

        return {
            ...requestInfo,
            headers: {
                'X-CSRF-Token': `${csrfToken}`,
                ...headers, // specific headers should win over generic ones.
            },
        };
    };
}

function useRedirectOnError(
    state: ServerRequestState<unknown, unknown>,
    noUnauthRefresh: boolean,
) {
    useEffect(() => {
        if (
            !noUnauthRefresh &&
            state.status === RequestStatus.ERROR &&
            state.httpStatusCode === 401
        ) {
            location.href = '';
        }
    }, [state, noUnauthRefresh]);
}

type NoContext = Record<string, never>;

export function useParkingaboServerFetch<
    Data,
    Context extends Record<string, unknown> = NoContext,
    ErrorData = null,
>(
    requestInfoFactory: FetchInfo<Context>,
    context: Context | null,
    noUnauthRefresh?: boolean,
): ServerFetchState<Data, ErrorData> {
    const ctx = useContext(ApiMiddlewareContext);

    const wrappedFactory = wrapRequestFactory(
        requestInfoFactory,
        ctx.csrfToken,
    );
    const [state, refetchSameContext] = useServerFetch<
        Data,
        Context,
        ErrorData
    >(wrappedFactory, context);

    useRedirectOnError(state, noUnauthRefresh ?? false);

    return [state, refetchSameContext];
}

export function useParkingaboServerWrite<
    Payload,
    Data,
    ErrorData = ValidationData,
>(
    requestInfoFactory: WriteInfo<Payload>,
    noUnauthRefresh?: boolean,
): ServerWriteState<Data, ErrorData, Payload> {
    const ctx = useContext(ApiMiddlewareContext);
    const wrappedFactory = wrapRequestFactory(
        requestInfoFactory,
        ctx.csrfToken,
    );
    const [state, write, reset] = useServerWrite<Payload, Data, ErrorData>(
        wrappedFactory,
    );

    useRedirectOnError(state, noUnauthRefresh ?? false);

    return [state, write, reset];
}
