import {
    DiscardChangesDialogProps,
    RoutedModal,
    RoutedModalProps,
} from 'dg-web-shared/common/components/material-ui/routed-modal/RoutedModal';
import { isMobile } from 'dg-web-shared/common/utils/BrowserOrigin';
import { MAX_HEIGHT_DESKTOP, MAX_WIDTH_DESKTOP } from '../../MainLayout';
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
} from '@mui/material';
import { Localized } from 'dg-web-shared/common/hooks/LanguageProvider.tsx';

export function ParkingaboRoutedModal(props: RoutedModalProps) {
    return (
        <RoutedModal
            {...props}
            DiscardChangesDialog={DiscardChangesDialog}
            style={{
                top: !isMobile() ? 0 : '50%',
                transform: !isMobile()
                    ? 'translate(-50%, 0)'
                    : 'translate(-50%, -50%)',
                width: '100%',
                height: '100%',
                maxWidth: !isMobile() ? MAX_WIDTH_DESKTOP : undefined,
                position: 'relative',
                maxHeight: !isMobile() ? MAX_HEIGHT_DESKTOP : undefined,
            }}
            hideBackdrop
            hideShadows
        />
    );
}

export function DiscardChangesDialog({
    open,
    onLeave,
    onStay,
}: DiscardChangesDialogProps) {
    return (
        <Dialog open={open} maxWidth="xs">
            <DialogTitle>
                <Localized
                    de="Änderungen verwerfen"
                    fr="Rejeter les changements"
                    it="Rifiutare le modifiche"
                    en="Discard changes"
                />
            </DialogTitle>
            <DialogContent>
                <Localized
                    de="Wenn Sie jetzt weiter machen, gehen alle Änderungen verloren."
                    fr="Si vous continuez maintenant, tous les changements seront perdus."
                    it="Se adesso continua, tutte le modifiche andranno perse."
                    en="If you continue now, all changes will be lost."
                />
            </DialogContent>
            <DialogActions>
                <Button onClick={onStay}>
                    <Localized
                        de="Zurück"
                        fr="Retour"
                        it="Indietro"
                        en="Back"
                    />
                </Button>
                <Button onClick={onLeave} variant="outlined" color="error">
                    <Localized
                        de="Weiter"
                        fr="Continuer"
                        it="Continua"
                        en="Continue"
                    />
                </Button>
            </DialogActions>
        </Dialog>
    );
}
