import {
    AuthedRouteCompProps,
    useParkingaboAuthedPathGeneration,
} from '../../RouteUtils';

import { EmailChangePending } from './EmailChangePending';
import { ParkingaboRoutedModal } from '../../../components/layout/ParkingaboRoutedModal';
import { ParkingaboUser } from '../../../shared/ParkingaboModels';
import {
    Localized,
    useLanguage,
} from 'dg-web-shared/common/hooks/LanguageProvider';
import { Box, Typography, useTheme } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useRequestEmailChange } from '../../../hooks/UseRequestEmailChange';
import { useServerSuccessEffect } from 'dg-web-shared/lib/hooks/ServerStateHooks';
import { useEasyForm } from 'dg-web-shared/common/utils/FormHooksUtils';
import { ParkingaboLayoutWithHeader } from '../../../components/layout/ParkingaboLayoutWithHeader';
import { ReactHookFormTextField } from 'dg-web-shared/common/components/material-ui/react-hook-form-fields/ReactHookFormTextField';
import { CancelSave } from '../../../components/layout/CancelSave';
import { GenericFormSubmitError } from '../../../shared/GenericFormSubmitError.tsx';

export function EditEmailRoute({ user, refetchUser }: AuthedRouteCompProps) {
    const generateAuthedParkingaboPath = useParkingaboAuthedPathGeneration();
    return (
        <ParkingaboRoutedModal
            backUrl={generateAuthedParkingaboPath('settings')}
            open={true}
            render={() => {
                if (user.requestedEmail !== null) {
                    return (
                        <EmailChangePending
                            user={user}
                            refetchUser={refetchUser}
                        />
                    );
                }

                return <EditEmail user={user} refetchUser={refetchUser} />;
            }}
        />
    );

    function EditEmail({
        refetchUser,
    }: {
        user: ParkingaboUser;
        refetchUser: () => void;
    }) {
        const { language } = useLanguage();
        const theme = useTheme();
        const navigate = useNavigate();

        const [changeState, sendChange] = useRequestEmailChange();

        useServerSuccessEffect(changeState, refetchUser);

        const {
            formInfo: { control, handleSubmit, watch },
            formState: { isDirty },
            genericSubmitError,
        } = useEasyForm(undefined, changeState, language, {
            defaultValues: {
                email: '',
            },
        });

        return (
            <form
                style={{ height: '100%' }}
                onSubmit={handleSubmit((c: { email: string }) => sendChange(c))}
            >
                <ParkingaboLayoutWithHeader
                    backTo={generateAuthedParkingaboPath('settings/user')}
                    title={
                        <Localized
                            de="Benutzer"
                            fr="Utilisateur"
                            it="Utente"
                            en="User"
                        />
                    }
                >
                    <Box>
                        <ReactHookFormTextField
                            style={{
                                marginBottom: theme.spacing(3),
                            }}
                            required={true}
                            control={control}
                            name="email"
                            label={
                                <Localized
                                    de="Neue E-Mail-Adresse"
                                    fr="Nouvelle adresse e-mail"
                                    it="Nuovo indirizzo e-mail"
                                    en="New email address"
                                />
                            }
                            type="email"
                        />
                        <GenericFormSubmitError
                            error={genericSubmitError}
                            submitState={changeState}
                        />
                        <Typography style={{ marginBottom: theme.spacing(3) }}>
                            <Localized
                                de="Ein Bestätigungslink wird per E-Mail an die neue Adresse gesendet. Bitte öffnen Sie den Link, um die Änderung der E-Mail-Adresse zu bestätigen."
                                fr="Un lien de confirmation sera envoyé par e-mail à la nouvelle adresse. Veuillez ouvrir le lien afin de finaliser le changement d'adresse e-mail."
                                it="Un link di conferma verrà inviato via e-mail al nuovo indirizzo. La preghiamo di aprire il link per confermare la modifica dell'indirizzo e-mail."
                                en="The e-mail confirmation link will be sent to the new address. Please open the link in order to confirm the e-mail address change."
                            />
                        </Typography>
                        <Typography style={{ fontWeight: 'bold' }}>
                            <Localized
                                de="Bis zur Bestätigung bleibt die aktuelle E-Mail-Adresse gültig."
                                fr="L'adresse e-mail actuelle reste valide jusqu'à confirmation."
                                it="L'indirizzo e-mail attuale rimane valido fino alla conferma."
                                en="The current e-mail address remains valid until confirmation."
                            />
                        </Typography>
                    </Box>
                    <CancelSave
                        requestState={changeState}
                        onCancel={() => navigate('..')}
                        saveDisabled={!isDirty || watch('email').length === 0}
                    />
                </ParkingaboLayoutWithHeader>
            </form>
        );
    }
}
