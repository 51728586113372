import { Box, Typography } from '@mui/material';

import { Localized } from 'dg-web-shared/common/hooks/LanguageProvider';
import { ParkingaboLink } from '../ParkinaboLink';

interface ParkingaboHeaderProps {
    to?: string;
    linkLabel?: React.ReactNode | string;
    onLinkClick?: () => void;
}

export function ParkingaboHeader({
    to,
    linkLabel,
    onLinkClick,
}: ParkingaboHeaderProps) {
    return (
        <Box
            style={{
                display: 'flex',
                justifyContent: to ? 'space-between' : 'flex-end',
                alignItems: 'center',
            }}
        >
            {to && (
                <ParkingaboLink to={to} onClick={onLinkClick}>
                    {linkLabel}
                </ParkingaboLink>
            )}
            <Typography variant="h2">
                <Localized
                    de="Parkingabo"
                    fr="Parkingabo"
                    it="Parkingabo"
                    en="Parkingabo"
                />
            </Typography>
        </Box>
    );
}
