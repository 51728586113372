export enum TenantAllowedBarrierGateVehicleIdentification {
    LICENSE_PLATE_QR = 'LICENSE_PLATE_QR',
    LICENSE_PLATE_BADGE = 'LICENSE_PLATE_BADGE',
    BADGE = 'BADGE',
}

export enum TenantAllowedEnforcedVehicleIdentification {
    LICENSE_PLATE = 'LICENSE_PLATE',
}

export enum TenantPaymentMode {
    DIGITAL_PURCHASE = 'DIGITAL_PURCHASE',
    COUNTER = 'COUNTER',
}

export enum TenantAllowedCustomerLegalForm {
    PRIVATE_ONLY = 'PRIVATE_ONLY',
    COMPANY_ONLY = 'COMPANY_ONLY',
    PRIVATE_AND_COMPANY = 'PRIVATE_AND_COMPANY',
}

export enum AppMode {
    NONE = 'NONE',
    NO_SIGNUP = 'NO_SIGNUP',
    SIGNUP_BY_EMAIL_DOMAIN = 'SIGNUP_BY_EMAIL_DOMAIN',
}
