import { Language } from 'dg-web-shared/lib/Localized.ts';
import { ParkingaboUserBadgeType } from 'dg-web-shared/common/models/Vehicle.tsx';
import { EmDash } from 'dg-web-shared/lib/Punctuation.ts';
import {
    TenantAllowedBarrierGateVehicleIdentification,
    TenantAllowedEnforcedVehicleIdentification,
} from './TenantEnums.ts';

export function getDisplayLabelForBadge(
    language: Language,
    badgeLabel: string | null,
    badgeType: ParkingaboUserBadgeType | null,
): string {
    const formattedLabel = formatBadgeLabelByType(badgeLabel, badgeType);
    if (formattedLabel && badgeType) {
        switch (badgeType) {
            case ParkingaboUserBadgeType.ParkingaboBadge:
                return `${formattedLabel} (Parkingabo)`;
            case ParkingaboUserBadgeType.ParkingpayBadge:
                return `${formattedLabel} (${parkingpayBadgeTranslation[language]})`;
        }
    }
    return EmDash;
}

export function formatBadgeLabelByType(
    badgeLabel: string | null,
    badgeType: ParkingaboUserBadgeType | null,
): string | null {
    if (badgeLabel && badgeType) {
        switch (badgeType) {
            case ParkingaboUserBadgeType.ParkingaboBadge:
                return `${badgeLabel.slice(0, 1)} ${badgeLabel.slice(1).match(/.{4}/g)?.join(' ')}`;
            case ParkingaboUserBadgeType.ParkingpayBadge:
                return `${badgeLabel.match(/.{1,3}/g)?.join(' ')}`;
        }
    }
    return null;
}

export function isLicensePlateMandatory(
    allowedEnforcedVehicleIdentification: TenantAllowedEnforcedVehicleIdentification | null,
    allowedBarrierGateVehicleIdentification: TenantAllowedBarrierGateVehicleIdentification | null,
): boolean {
    switch (allowedEnforcedVehicleIdentification) {
        case TenantAllowedEnforcedVehicleIdentification.LICENSE_PLATE:
            return true;
        case null:
            switch (allowedBarrierGateVehicleIdentification) {
                case TenantAllowedBarrierGateVehicleIdentification.LICENSE_PLATE_QR:
                case TenantAllowedBarrierGateVehicleIdentification.LICENSE_PLATE_BADGE:
                    return true;
                case TenantAllowedBarrierGateVehicleIdentification.BADGE:
                case null:
                    return false;
            }
    }
}

export const badgeTypeOptions = (lang: Language) =>
    Object.values(ParkingaboUserBadgeType).map(type => ({
        display: {
            [ParkingaboUserBadgeType.ParkingaboBadge]: {
                de: 'Parkingabo',
                fr: 'Parkingabo',
                it: 'Parkingabo',
                en: 'Parkingabo',
            },
            [ParkingaboUserBadgeType.ParkingpayBadge]:
                parkingpayBadgeTranslation,
        }[type][lang],
        value: type,
    }));

export const parkingpayBadgeTranslation = {
    de: 'Parkingpay / Mieter',
    fr: 'Parkingpay / Abonnement',
    it: 'Parkingpay / Abbonato',
    en: 'Parkingpay / Tenant',
};
