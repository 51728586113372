import { CircularProgress, Box, Modal, useTheme } from '@mui/material';
import { ReactNode } from 'react';

export function BlockingLoadingModal({
    open,
    children,
}: {
    open: boolean;
    children?: ReactNode | ReactNode[];
}) {
    const theme = useTheme();

    return (
        <Modal
            open={open}
            disableEscapeKeyDown={true}
            style={{
                backgroundColor: 'rgba(0, 0, 0, 0.5)',
            }}
        >
            <Box
                style={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    flexDirection: 'column',
                    textAlign: 'center',
                    color: theme.palette.common.white,
                }}
            >
                <CircularProgress
                    style={{
                        margin: theme.spacing(3),
                        color: theme.palette.common.white,
                    }}
                />
                {children}
            </Box>
        </Modal>
    );
}
