import { Localized } from 'dg-web-shared/common/hooks/LanguageProvider';
import { FeedbackPopup } from '../../../components/FeedbackPopup';
import { useNavigate } from 'react-router-dom';

export function EmailResentConfirmationRoute() {
    const navigate = useNavigate();

    return (
        <FeedbackPopup
            open={true}
            color="success"
            onAbort={() => {
                navigate('..');
            }}
            abortLabel={
                <Localized
                    de="Weiter"
                    fr="Continuer"
                    it="Avanti"
                    en="Proceed"
                />
            }
            title={
                <Localized
                    de="E-Mail Bestätigungslink angefordert"
                    fr="Lien de confirmation de l'e-mail demandé"
                    it="Link di conferma dell'e-mail richiesto"
                    en="Email confirmation link requested"
                />
            }
        >
            <Localized
                de="Bitte überprüfen Sie Ihre E-Mail und klicken Sie auf den Aktivierungslink, um die Änderung Ihrer E-Mail-Adresse zu bestätigen."
                fr="Veuillez vérifier votre e-mail et cliquer sur le lien d'activation pour confirmer le changement de l'adresse e-mail."
                it="Controlli la sua e-mail e clicchi sul link di attivazione per confermare il cambio di indirizzo e-mail."
                en="Please check your email and click the activation link to confirm the email address change."
            />
        </FeedbackPopup>
    );
}
