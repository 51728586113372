import { Theme } from '@mui/material';
import { parkingaboDefaultMuiTheme } from '../shared/ParkingaboDefaultMuiTheme';
import { parkingaboCoopMuiTheme } from '../shared/ParkingaboCoopMuiTheme';

export enum ParkingaboSubdomain {
    Digitalparking = 'app',
    Coop = 'coop',
    CARDCENTER = 'cardcenter',
    UBS = 'ubs',
}

export const parkingaboThemeConfigs: {
    [name in ParkingaboSubdomain]: { displayName: string; theme: Theme };
} = {
    [ParkingaboSubdomain.Digitalparking]: {
        displayName: 'Parkingabo',
        theme: parkingaboDefaultMuiTheme,
    },
    [ParkingaboSubdomain.Coop]: {
        displayName: 'Coop',
        theme: parkingaboCoopMuiTheme,
    },
    [ParkingaboSubdomain.CARDCENTER]: {
        displayName: 'UBS Card Center',
        theme: parkingaboDefaultMuiTheme,
    },
    [ParkingaboSubdomain.UBS]: {
        displayName: 'UBS',
        theme: parkingaboDefaultMuiTheme,
    },
};
