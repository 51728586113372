import {
    ParkingaboProductListItem,
    ParkingaboProductType,
} from '../../../shared/ParkingaboProductModels.ts';
import {
    ProductDetailBody,
    ProductOverviewList,
} from './ProductsDetailOutlet.tsx';

export function ProductDetailPermitUntilRevocation({
    product,
}: {
    product: ParkingaboProductListItem;
}) {
    if (product.type !== ParkingaboProductType.PERMIT_UNTIL_REVOCATION) {
        throw new Error('product type must be permitUntilRevocation');
    }

    return (
        <ProductDetailBody>
            <ProductOverviewList product={product} />
        </ProductDetailBody>
    );
}
