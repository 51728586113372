import { Box, Typography } from '@mui/material';

import { ParkingaboLayout } from './ParkingaboLayout';
import { ParkingaboLink } from '../ParkinaboLink';
import { Close } from '@mui/icons-material';

export function ParkingaboLayoutWithHeader({
    title,
    backTo,
    noGutter,
    children,
    scrollable,
}: {
    title: string | React.ReactNode;
    backTo?: string;
    noGutter?: boolean;
    children?: React.ReactNode | React.ReactNodeArray;
    scrollable?: boolean;
}) {
    return (
        <ParkingaboLayout style={{ padding: 0 }}>
            <ParkingaboScreenHeader backTo={backTo} label={title} />
            <Box
                sx={{
                    padding: theme => (noGutter ? 0 : theme.spacing(3)),
                    flexGrow: 1,
                    display: 'flex',
                    justifyContent: 'space-between',
                    flexDirection: 'column',
                    position: 'relative',
                    overflowX: 'hidden',
                    overflowY: scrollable ? 'scroll' : 'hidden',
                    scrollbarWidth: 'none' /* Firefox */,
                    msOverflowStyle: 'none' /* Internet Explorer, Edge */,
                    '&::-webkit-scrollbar': {
                        display: 'none',
                    } /* Chrome */,
                }}
            >
                {children}
            </Box>
        </ParkingaboLayout>
    );
}

function ParkingaboScreenHeader({
    label,
    backTo,
}: {
    label: React.ReactNode;
    backTo?: string;
}) {
    return (
        <Box
            sx={{
                display: 'flex',
                flexShrink: 0,
                justifyContent: 'space-between',
                alignItems: 'center',
                height: 54,
                paddingX: 3,
                backgroundColor: theme => theme.palette.primary.main,
            }}
        >
            <Typography
                variant="h2"
                sx={{ color: theme => theme.palette.primary.contrastText }}
            >
                {label}
            </Typography>
            {backTo && (
                <ParkingaboLink
                    to={backTo}
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        color: theme => theme.palette.primary.contrastText,
                    }}
                >
                    <Close />
                </ParkingaboLink>
            )}
        </Box>
    );
}
