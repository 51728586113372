import { Typography, useTheme } from '@mui/material';
import { Localized } from 'dg-web-shared/common/hooks/LanguageProvider';
import { ParkingaboHeader } from '../../../components/layout/ParkingaboHeader';
import { ParkingaboLayout } from '../../../components/layout/ParkingaboLayout';
import {
    AuthedRouteCompProps,
    useParkingaboAuthedPathGeneration,
} from '../../RouteUtils';
import {
    OnboardingStepType,
    useNavigateOnStepCompletion,
    useOnboarding,
    useSetOnboardingStepAsCompleteOnSuccess,
} from './OnboardingConfig';
import {
    VehicleForm,
    VehicleOnboardingPayload,
} from '../../../components/forms/VehicleForm';
import { ParkingaboAsyncLoadedSection } from '../../../components/layout/ParkingaboAsyncLoadedSection';
import { useParkingaboServerWrite } from '../../../api/ParkingaboApi';
import { useServerSuccessEffect } from 'dg-web-shared/lib/hooks/ServerStateHooks';
import {
    makeCountryOptions,
    useCountryFetch,
} from '../../../hooks/UseCountryFetch';

export function OnboardingVehicleRoute({ vehicles }: AuthedRouteCompProps) {
    const theme = useTheme();
    const { config } = useOnboarding();
    const currentStep = config.steps.findIndex(
        c => c.type === OnboardingStepType.VEHICLE,
    );
    const totalSteps = config.steps.length;
    const generateAuthedParkingaboPath = useParkingaboAuthedPathGeneration();

    const [countriesState] = useCountryFetch();

    const [state, submit] = useParkingaboServerWrite<
        VehicleOnboardingPayload,
        never
    >(() => ({
        url: '/ui-api/parkingabo/user/self/vehicles',
    }));

    useSetOnboardingStepAsCompleteOnSuccess(state, currentStep);
    useServerSuccessEffect(state, vehicles.refetch);
    useNavigateOnStepCompletion(currentStep, generateAuthedParkingaboPath(''));

    return (
        <ParkingaboAsyncLoadedSection
            state={countriesState}
            render={data => {
                return (
                    <ParkingaboLayout>
                        <ParkingaboHeader />
                        <Typography
                            variant="h1"
                            style={{
                                marginTop: theme.spacing(3),
                            }}
                        >
                            <Localized
                                de="Fahrzeug"
                                fr="Véhicule"
                                it="Veicolo"
                                en="Vehicle"
                            />
                        </Typography>
                        <Typography
                            variant="h3"
                            style={{
                                marginBottom: theme.spacing(5),
                                marginTop: theme.spacing(2),
                            }}
                        >
                            <Localized
                                de={`Konfiguration (${
                                    currentStep + 1
                                } von ${totalSteps})`}
                                fr={`Configuration (${
                                    currentStep + 1
                                } de ${totalSteps})`}
                                it={`Configurazione (${
                                    currentStep + 1
                                } di ${totalSteps})`}
                                en={`Setup (${
                                    currentStep + 1
                                } of ${totalSteps})`}
                            />
                        </Typography>
                        <VehicleForm
                            countriesOptions={makeCountryOptions(data)}
                            requestState={state}
                            isOnboarding={true}
                            submit={submit}
                        />
                    </ParkingaboLayout>
                );
            }}
        />
    );
}
