import { ReactNode, useEffect } from 'react';
import { DateTime } from 'luxon';
import { Box, Typography } from '@mui/material';
import { Localized } from 'dg-web-shared/common/hooks/LanguageProvider';

export interface TimeSelection {
    hour: number | null;
    minute: number | null;
}

export function ParkingaboTimePicker({
    date,
    time,
    onTimeChange,
    minDateTime,
    maxDateTime,
    disablePast,
}: {
    date: DateTime;
    time: TimeSelection;
    onTimeChange: (value: TimeSelection, userInteraction: boolean) => void;
    minDateTime?: DateTime;
    maxDateTime?: DateTime;
    disablePast?: boolean;
}) {
    const startOfDay = date.startOf('day');
    const aggMinDateTime = getAggregatedMinDateTime(minDateTime, disablePast);
    const hourSteps = Array.from(Array(24).keys()).map(value => ({
        value: value,
        active: value === time.hour,
        disabled:
            (!!aggMinDateTime &&
                aggMinDateTime >
                    startOfDay.plus({
                        hour: value,
                        minute: Math.max(...minuteSteps),
                    })) ||
            (!!maxDateTime &&
                maxDateTime <
                    startOfDay.plus({
                        hour: value,
                        minute: Math.min(...minuteSteps),
                    })),
    }));
    const minSteps = minuteSteps.map(value => ({
        value: value,
        active: value === time.minute,
        disabled:
            time.hour === null ||
            (!!aggMinDateTime &&
                aggMinDateTime >
                    startOfDay.plus({
                        hour: time.hour,
                        minute: value,
                    })) ||
            (!!maxDateTime &&
                maxDateTime <
                    startOfDay.plus({
                        hour: time.hour,
                        minute: value,
                    })),
    }));

    useEffect(() => {
        const nonDisabledHourSteps = hourSteps.filter(step => !step.disabled);
        if (
            nonDisabledHourSteps.length === 1 &&
            !nonDisabledHourSteps[0].active
        ) {
            onTimeChange(
                {
                    hour: nonDisabledHourSteps[0].value,
                    minute: null,
                },
                false,
            );
        }
    }, [hourSteps]);

    useEffect(() => {
        const nonDisabledMinuteSteps = minSteps.filter(step => !step.disabled);
        if (
            nonDisabledMinuteSteps.length === 1 &&
            !nonDisabledMinuteSteps[0].active
        ) {
            onTimeChange(
                {
                    ...time,
                    minute: nonDisabledMinuteSteps[0].value,
                },
                false,
            );
        }
    }, [minSteps]);

    return (
        <div>
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                    width: '100%',
                }}
            >
                <Typography
                    sx={{
                        maxWidth: '236px',
                        paddingY: 2,
                        fontSize: 32,
                        fontWeight: theme => theme.typography.fontWeightMedium,
                    }}
                >
                    {time.hour?.toString().padStart(2, '0') ?? '--'}:
                    {time.minute?.toString().padStart(2, '0') ?? '--'}
                </Typography>
                <Box sx={{ maxWidth: '236px' }}>
                    <Typography fontWeight="medium" marginBottom={1}>
                        <Localized
                            de="Stunden"
                            fr="Heures"
                            it="Ore"
                            en="Hours"
                        />
                    </Typography>
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'flex-start',
                            alignItems: 'flex-start',
                            gap: '4px',
                            flexWrap: 'wrap',
                            marginBottom: 4,
                        }}
                    >
                        {hourSteps.map(step => (
                            <TimeSelectionButton
                                key={step.value}
                                active={step.active}
                                disabled={step.disabled}
                                onClick={() =>
                                    onTimeChange(
                                        {
                                            hour: step.value,
                                            minute: null,
                                        },
                                        true,
                                    )
                                }
                            >
                                {step.value}
                            </TimeSelectionButton>
                        ))}
                    </Box>
                    <Typography fontWeight="medium" marginBottom={1}>
                        <Localized
                            de="Minuten"
                            fr="Minutes"
                            it="Minuti"
                            en="Minutes"
                        />
                    </Typography>
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'flex-start',
                            alignItems: 'flex-start',
                            gap: '4px',
                            flexWrap: 'wrap',
                        }}
                    >
                        {minSteps.map(step => (
                            <TimeSelectionButton
                                key={step.value}
                                active={step.active}
                                disabled={step.disabled}
                                onClick={() =>
                                    onTimeChange(
                                        {
                                            ...time,
                                            minute: step.value,
                                        },
                                        true,
                                    )
                                }
                            >
                                {step.value}
                            </TimeSelectionButton>
                        ))}
                    </Box>
                </Box>
            </Box>
        </div>
    );
}

function TimeSelectionButton({
    active,
    disabled,
    onClick,
    children,
}: {
    active: boolean;
    disabled: boolean;
    onClick: () => void;
    children: ReactNode;
}) {
    const hoverStyle = {
        backgroundColor: active || disabled ? undefined : 'rgba(0, 0, 0, 0.04)',
    };
    return (
        <Box
            sx={{
                height: '36px',
                width: '36px',
                fontSize: 14,
                fontWeight: theme => theme.typography.fontWeightRegular,
                borderRadius: '9999px',
                border: '1px',
                outline: 'inherit',
                flexShrink: 0,
                flexGrow: 0,
                backgroundColor: theme =>
                    active && !disabled
                        ? theme.palette.primary.main
                        : theme.palette.background.default,
                color: theme =>
                    disabled
                        ? theme.palette.text.disabled
                        : active
                          ? theme.palette.primary.contrastText
                          : theme.palette.primary.main,
                '@media(hover: hover)': {
                    ':hover': hoverStyle,
                },
                '@media(hover: none)': {
                    ':active': hoverStyle,
                },
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                cursor: disabled ? 'auto' : 'pointer',
                userSelect: 'none',
                pointerEvents: disabled || active ? 'none' : undefined,
                '-webkit-tap-highlight-color': 'transparent',
            }}
            onClick={disabled || active ? undefined : onClick}
        >
            {children}
        </Box>
    );
}

const minuteSteps = [0, 15, 30, 45];

function getAggregatedMinDateTime(
    minDateTime?: DateTime,
    disablePast?: boolean,
): DateTime | undefined {
    if (disablePast) {
        const now = DateTime.now();
        if (minDateTime) {
            return minDateTime < now ? now : minDateTime;
        } else {
            return now;
        }
    } else {
        return minDateTime;
    }
}
