import {
    Dialog,
    DialogTitle,
    useTheme,
    DialogContent,
    DialogActions,
    Box,
} from '@mui/material';

import { Localized } from 'dg-web-shared/common/hooks/LanguageProvider';
import { ParkingaboButton } from './layout/ParkingaboButton';
import { ReactNode } from 'react';

export function FeedbackPopup({
    open,
    onAbort,
    onConfirm,
    title,
    color,
    children,
    abortLabel,
    confirmLabel,
}: {
    open: boolean;
    onAbort?: () => void;
    onConfirm?: () => void;
    title: ReactNode;
    color?: 'error' | 'warning' | 'success';
    children?: ReactNode | ReactNode[];
    abortLabel?: ReactNode;
    confirmLabel?: ReactNode;
}) {
    const theme = useTheme();

    return (
        <Dialog open={open} onClose={onAbort} fullWidth={true}>
            <DialogTitle
                sx={{
                    background: theme.palette[color || 'warning'].main,
                    color: theme.palette[color || 'warning'].contrastText,
                    paddingY: 1,
                }}
            >
                {title}
            </DialogTitle>
            <Box>
                <DialogContent
                    sx={{
                        paddingTop: 2,
                        paddingBottom: onAbort || onConfirm ? 1 : 3,
                    }}
                >
                    {children}
                </DialogContent>
            </Box>
            <DialogActions>
                {onAbort && (
                    <ParkingaboButton variant={'text'} onClick={onAbort}>
                        {abortLabel ?? (
                            <Localized
                                de="Abbrechen"
                                fr="Annuler"
                                it="Annulla"
                                en="Cancel"
                            />
                        )}
                    </ParkingaboButton>
                )}
                {onConfirm && (
                    <ParkingaboButton variant={'text'} onClick={onConfirm}>
                        {confirmLabel ?? 'OK'}
                    </ParkingaboButton>
                )}
            </DialogActions>
        </Dialog>
    );
}
