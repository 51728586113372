import { Localized } from 'dg-web-shared/common/hooks/LanguageProvider';
import { ParkingaboLink } from '../../components/ParkinaboLink';
import { useNavigate } from 'react-router-dom';
import { FeedbackPopup } from '../../components/FeedbackPopup';

export function ResetPasswordTokenErrorRoute() {
    const navigate = useNavigate();
    return (
        <FeedbackPopup
            open={true}
            color="error"
            onAbort={() => {
                navigate('/login');
            }}
            abortLabel={
                <Localized de="Schliessen" fr="Fermer" it="Chiudi" en="Close" />
            }
            title={
                <Localized
                    de="Password ersetzen"
                    fr="Remplacer le mot de passe"
                    it="Sostituire la password"
                    en="Replace password"
                />
            }
        >
            <Localized
                de="Dieser Link ist nicht mehr gültig. Sie konnen "
                fr="Ce lien n'est plus valide. Vous pouvez demander "
                it="Questo link non è più valido. Può richiedere "
                en="This link is no longer valid. You can request "
            />
            <ParkingaboLink
                to={'/request-password-reset'}
                color="error"
                underline="always"
            >
                <Localized
                    de="hier ein neuer Link"
                    fr="ici un nouveau lien"
                    it="qui un nuovo link"
                    en="here a new link"
                />
            </ParkingaboLink>
            <Localized de=" anfordern." fr="." it="." en="." />
        </FeedbackPopup>
    );
}
