import { Outlet, useNavigate } from 'react-router-dom';
import { AuthedRouteCompProps } from '../routes/RouteUtils';
import { createContext, useContext, useEffect } from 'react';
import { useParamOrNull } from 'dg-web-shared/lib/ReactRouterHelpers.ts';
import { useParkingaboServerFetch } from '../api/ParkingaboApi.tsx';
import { Tenant } from '../shared/ParkingaboModels.tsx';
import { ParkingaboAsyncLoadedSection } from './layout/ParkingaboAsyncLoadedSection.tsx';

export const TenantContext = createContext<{
    tenant: Tenant | null;
}>({ tenant: null });

export function TenantContextProvider({ user }: AuthedRouteCompProps) {
    const tenantId = Number(useParamOrNull('tenantId'));
    const navigate = useNavigate();

    /**
     * In future it should check here that the tenantId from the path is allowed for this login
     * And otherwise it should redirect to a tenant selection
     */
    useEffect(() => {
        if (tenantId !== user.tenantId) {
            navigate(`/${user.tenantId}`);
        }
    }, [tenantId, user.tenantId]);

    const [tenantState] = useParkingaboServerFetch<
        Tenant,
        { tenantId: number }
    >(
        ({ tenantId }) => ({
            url: `/ui-api/parkingabo/tenant/${tenantId}`,
        }),
        tenantId ? { tenantId } : null,
    );

    return (
        <ParkingaboAsyncLoadedSection
            state={tenantState}
            render={tenant => (
                <TenantContext.Provider
                    value={{
                        tenant: tenant,
                    }}
                >
                    <Outlet />
                </TenantContext.Provider>
            )}
        />
    );
}

export function useTenant(): { tenant: Tenant } {
    const context = useContext(TenantContext);
    return { tenant: context.tenant! };
}
