import * as StringConversions from './StringConversions';
import { Language, Translations } from './Text.ts';

export enum RfidCardState {
    HAS_NOT_EXACT_LENGTH,
    INVALID,
}

export function hasState(
    states: RfidCardState[],
    state: RfidCardState,
): boolean {
    return states.indexOf(state) !== -1;
}

export interface RfidCardErrorTexts {
    HasNotExactLength: () => string;
    Invalid: () => string;
}

export function rfidCardErrorText(
    rfidString: string,
    language: Language,
): string {
    const texts = parkingpayBadgeErrorTexts[language];
    const states = getRfidCardStates(rfidString);
    if (hasState(states, RfidCardState.HAS_NOT_EXACT_LENGTH)) {
        return texts.HasNotExactLength();
    }
    if (hasState(states, RfidCardState.INVALID)) {
        return texts.Invalid();
    }
    return '';
}

const parkingpayBadgeErrorTexts: Translations<RfidCardErrorTexts> = {
    de: {
        HasNotExactLength: (): string =>
            'Die Badge-Nummer muss 12 Ziffern lang sein',
        Invalid: (): string => 'Invalide Badge-Nummer',
    },
    fr: {
        HasNotExactLength: (): string =>
            'Le numéro de badge doit comporter 12 chiffres',
        Invalid: (): string => 'Numéro de badge invalide',
    },
    it: {
        HasNotExactLength: (): string =>
            'Il numero di badge deve essere di 12 cifre',
        Invalid: (): string => 'Numero di badge non valido',
    },
    en: {
        HasNotExactLength: (): string => 'The badge number must be 12 digits',
        Invalid: (): string => 'Invalid badge number',
    },
};

const PARKINGABO_BADGE_ALLOWED_CHARS =
    '0Oo1IiLl2-9AaBbCcDdEeFfGgHhJjKkMmNnPpQqRrSsTtVvWwXxYyZz';
const PARKINGABO_BADGE_ALLOWED_12TH_POSITION = '0OoGg';
export function parkingaboBadgeErrorText(
    label: string,
    language: Language,
): string {
    const texts = parkingaboBadgeErrorTexts[language];
    if (label.length !== 13) {
        return texts.HasNotExactLength();
    }
    if (
        !label.match(
            new RegExp(
                `^[${PARKINGABO_BADGE_ALLOWED_CHARS}]{11}[${PARKINGABO_BADGE_ALLOWED_12TH_POSITION}][${PARKINGABO_BADGE_ALLOWED_CHARS}]$`,
            ),
        )
    ) {
        return texts.Invalid();
    }
    return '';
}

const parkingaboBadgeErrorTexts: Translations<RfidCardErrorTexts> = {
    de: {
        HasNotExactLength: (): string =>
            'Die Badge-Nummer muss 13 Zeichen lang sein',
        Invalid: (): string => 'Invalide Badge-Nummer',
    },
    fr: {
        HasNotExactLength: (): string =>
            'Le numéro de badge doit comporter 13 caractères',
        Invalid: (): string => 'Numéro de badge invalide',
    },
    it: {
        HasNotExactLength: (): string =>
            'Il numero di badge deve essere di 13 caratteri',
        Invalid: (): string => 'Numero di badge non valido',
    },
    en: {
        HasNotExactLength: (): string =>
            'The badge number must be 13 characters long',
        Invalid: (): string => 'Invalid badge number',
    },
};

export function getRfidCardStates(rfidString: string): RfidCardState[] {
    const states: RfidCardState[] = [];
    if (StringConversions.stripNonNumericCharacters(rfidString).length !== 12) {
        states.push(RfidCardState.HAS_NOT_EXACT_LENGTH);
    }
    if (!validBadgeNumber(rfidString)) {
        states.push(RfidCardState.INVALID);
    }
    return states;
}

export function validBadgeNumber(badgeNumber: string): boolean {
    const bytes =
        StringConversions.stripNonNumericCharacters(badgeNumber).match(
            /.{1,3}/g,
        );
    if (!bytes) {
        return false;
    }
    return (
        bytes.every((b: string) => parseInt(b, 10) <= 255) &&
        !bytes.every((b: string) => parseInt(b, 10) === 0)
    );
}
