import {
    Control,
    Controller,
    FieldValues,
    Path,
    RegisterOptions,
} from 'react-hook-form';
import { TextField, TextFieldProps } from '@mui/material';

type ReactHookFormTextFieldProps<FormFields extends FieldValues> =
    TextFieldProps & {
        control: Control<FormFields>;
        name: Path<FormFields>;
        autofocus?: boolean;
        readonly?: boolean;
        trimOnBlur?: boolean;
        rules?: Omit<
            RegisterOptions<FormFields, Path<FormFields>>,
            'valueAsNumber' | 'valueAsDate' | 'setValueAs' | 'disabled'
        >;
    };

export function ReactHookFormTextField<FormFields extends FieldValues>({
    name,
    control,
    readonly,
    rules,
    trimOnBlur,
    onChange: externalChange,
    ...props
}: ReactHookFormTextFieldProps<FormFields>) {
    return (
        <Controller
            name={name}
            control={control}
            rules={rules}
            render={({
                field: { onChange, value, ref },
                fieldState: { error, isTouched },
            }) => (
                <TextField
                    ref={ref}
                    onChange={(...params) => {
                        onChange(...params);
                        if (externalChange) {
                            externalChange(...params);
                        }
                    }}
                    value={value ?? ''}
                    error={!!error && !isTouched}
                    helperText={error && !isTouched ? error.message : null}
                    disabled={readonly}
                    onBlur={e => {
                        props.onBlur && props.onBlur(e);
                        trimOnBlur && onChange(value.trim());
                    }}
                    {...props}
                />
            )}
        />
    );
}
