import { useEffect, useMemo, useState } from 'react';
import { Localized } from 'dg-web-shared/common/hooks/LanguageProvider';
import { Box, Typography } from '@mui/material';
import { DateTime, Duration } from 'luxon';
import {
    formatZoneName,
    ParkingaboProductTemplateReservation,
    WeekdayPrice,
} from './ParkingaboProductTemplateModels';
import {
    WizardBody,
    WizardBottomBar,
    WizardFadeInEffect,
    WizardProductHeader,
    WizardStepper,
} from './WizardComponents';
import { useNavigate } from 'react-router-dom';
import {
    useParkingaboServerFetch,
    useParkingaboServerWrite,
} from '../../../api/ParkingaboApi';
import {
    RequestStatus,
    useServerSuccessEffect,
} from 'dg-web-shared/lib/hooks/ServerStateHooks';
import { BackendRequestErrorMessage } from 'dg-web-shared/common/components/material-ui/BackendRequestErrorMessage';
import { compareDateTime, Formatter } from 'dg-web-shared/lib/Date';
import { currencyCentsToLocalPrice } from 'dg-web-shared/lib/NumberFormatter';
import { FeedbackPopup } from '../../../components/FeedbackPopup';
import { ParkingaboOverlineList } from '../../../components/layout/ParkingaboOverlineList';
import { ParkingaboZoneInfo } from '../../../shared/ParkingaboProductModels';
import {
    AvailabilityLevel,
    MultiDateSelectionStep,
    WeekDay,
} from './SharedSteps';
import { VehicleType } from './ProductsConfigurationOutlet';
import { useParkingaboAuthedPathGeneration } from '../../RouteUtils';

enum WizardStep {
    DATE,
    OVERVIEW,
}

interface WizardSelection {
    dates: DateTime[];
}

export function ProductsConfigurationReservation({
    product,
    zones,
    noProductChange,
    refetchProducts,
}: {
    product: ParkingaboProductTemplateReservation;
    zones: ParkingaboZoneInfo[];
    noProductChange: boolean;
    refetchProducts: () => void;
}) {
    const [selection, setSelection] = useState<WizardSelection>({
        dates: [],
    });
    const totalAmountRappen =
        selection.dates.length > 0
            ? calculateTotalPrice(product, selection.dates)
            : null;

    const [purchaseState, purchaseProduct, resetPurchaseState] =
        useParkingaboServerWrite<
            {
                contractTemplateId: number;
                dates: string[];
                totalAmountRappen: number;
            },
            never
        >(() => ({
            url: `/ui-api/parkingabo/user/self/product/purchase/reservation-day`,
        }));

    useServerSuccessEffect(purchaseState, refetchProducts);

    const navigate = useNavigate();

    useEffect(() => {
        if (purchaseState.status === RequestStatus.ERROR) {
            resetPurchaseState();
        }
    }, [selection]);

    function handlePurchase() {
        if (
            !selection.dates ||
            totalAmountRappen === null ||
            activeStep !== WizardStep.OVERVIEW
        ) {
            throw new Error(
                'Tried to purchase product without completed selection',
            );
        }
        purchaseProduct({
            contractTemplateId: product.contractTemplateId,
            dates: selection.dates.map(date => date.toISODate()),
            totalAmountRappen: totalAmountRappen,
        });
    }

    const steps = [
        {
            step: WizardStep.DATE,
            label: <Localized de="Datum" fr="Date" it="Data" en="Date" />,
        },
        {
            step: WizardStep.OVERVIEW,
            label: (
                <Localized
                    de="Übersicht"
                    fr="Aperçu"
                    it="Panoramica"
                    en="Overview"
                />
            ),
        },
    ];

    const firstStep = steps[0].step;
    const [activeStep, setActiveStep] = useState<WizardStep>(firstStep);

    const stepsWithClearance = useMemo(
        () => determineStepsWithClearance(selection, totalAmountRappen),
        [selection, totalAmountRappen],
    );

    const [availabilityState] = useParkingaboServerFetch<
        { [id: string]: AvailabilityLevel },
        {
            contractTemplateId: number;
            validFrom: string;
            validTo: string;
        }
    >(
        ctx => ({
            url: `/ui-api/parkingabo/user/self/product/availability-days?contractTemplateId=${encodeURIComponent(
                ctx.contractTemplateId,
            )}&validFrom=${encodeURIComponent(
                ctx.validFrom,
            )}&validTo=${encodeURIComponent(ctx.validTo)}`,
        }),
        {
            contractTemplateId: product.contractTemplateId,
            validFrom: DateTime.now().toISODate(),
            validTo: DateTime.now().plus(Duration.fromISO('P90D')).toISODate(),
        },
    );

    const generateAuthedParkingaboPath = useParkingaboAuthedPathGeneration();

    return (
        <>
            <BackendRequestErrorMessage requestState={purchaseState} />
            <PurchaseSuccessDialog
                open={purchaseState.status === RequestStatus.SUCCESS}
                onAbort={() =>
                    navigate(generateAuthedParkingaboPath('products'))
                }
            />
            <WizardProductHeader>
                <Localized {...product.name} />
            </WizardProductHeader>
            <WizardStepper
                steps={steps}
                stepsWithClearance={stepsWithClearance}
                activeStep={activeStep}
                onStepClick={setActiveStep}
                offset={noProductChange ? 0 : 3}
            />
            <WizardBody>
                {activeStep === WizardStep.DATE &&
                    (availabilityState.status == RequestStatus.SUCCESS ||
                        availabilityState.status == RequestStatus.ERROR) && (
                        <MultiDateSelectionStep
                            dates={selection.dates}
                            onChange={dates => {
                                setSelection({
                                    ...selection,
                                    dates,
                                });
                            }}
                            minValidityDate={
                                product.dayAndPriceConfig.minValidityStart
                                    ? DateTime.fromISO(
                                          product.dayAndPriceConfig
                                              .minValidityStart,
                                      )
                                    : null
                            }
                            maxSelectableDates={
                                product.dayAndPriceConfig.maxPurchaseQuantity!
                            }
                            maxDaysInFuture={
                                product.dayAndPriceConfig
                                    .maxDaysPurchaseAheadInFuture!
                            }
                            disabledWeekdays={deriveDisabledWeekDays(
                                product.dayAndPriceConfig.priceConfig,
                            )}
                            availability={availabilityState.data || undefined}
                        />
                    )}
                {activeStep === WizardStep.OVERVIEW && (
                    <ProductOverview
                        product={product}
                        zones={zones}
                        dates={selection.dates}
                        totalAmountRappen={totalAmountRappen!}
                    />
                )}
            </WizardBody>
            <WizardBottomBar
                onNextClick={
                    stepsWithClearance.includes(activeStep + 1)
                        ? () => {
                              if (activeStep === WizardStep.OVERVIEW) {
                                  handlePurchase();
                              } else {
                                  setActiveStep(activeStep + 1);
                              }
                          }
                        : undefined
                }
                nextLabel={
                    activeStep === WizardStep.OVERVIEW ? (
                        <Localized
                            de="Buchen"
                            fr="Réserver"
                            it="Prenota"
                            en="Book"
                        />
                    ) : undefined
                }
                nextAsSubmitRequestState={
                    activeStep === WizardStep.OVERVIEW
                        ? purchaseState.status
                        : undefined
                }
                onPreviousClick={() => {
                    if (activeStep === firstStep) {
                        navigate('..');
                    } else {
                        setActiveStep(activeStep - 1);
                    }
                }}
                previousLabel={
                    activeStep === firstStep ? (
                        <Localized
                            de="Produkt ändern"
                            fr="Changer de produit"
                            it="Cambia prodotto"
                            en="Change product"
                        />
                    ) : undefined
                }
                hidePrevious={noProductChange && activeStep === firstStep}
            />
        </>
    );
}

function deriveDisabledWeekDays(priceConfig: WeekdayPrice): WeekDay[] {
    const disabledWeekDays: WeekDay[] = [];
    if (priceConfig.mondayRappen === null) {
        disabledWeekDays.push(WeekDay.MONDAY);
    }
    if (priceConfig.tuesdayRappen === null) {
        disabledWeekDays.push(WeekDay.TUESDAY);
    }
    if (priceConfig.wednesdayRappen === null) {
        disabledWeekDays.push(WeekDay.WEDNESDAY);
    }
    if (priceConfig.thursdayRappen === null) {
        disabledWeekDays.push(WeekDay.THURSDAY);
    }
    if (priceConfig.fridayRappen === null) {
        disabledWeekDays.push(WeekDay.FRIDAY);
    }
    if (priceConfig.saturdayRappen === null) {
        disabledWeekDays.push(WeekDay.SATURDAY);
    }
    if (priceConfig.sundayRappen === null) {
        disabledWeekDays.push(WeekDay.SUNDAY);
    }
    return disabledWeekDays;
}

function determineStepsWithClearance(
    selection: WizardSelection,
    totalAmountRappen: number | null,
) {
    const steps = Object.values(WizardStep).filter(
        (step): step is WizardStep =>
            typeof step !== 'string' && typeof step !== 'undefined',
    );
    const stepsIncludingLastNextStep = [...steps, steps[steps.length - 1] + 1];
    return stepsIncludingLastNextStep.filter(step => {
        switch (step) {
            case WizardStep.DATE:
                return true;
            default:
                return selection.dates.length > 0 && totalAmountRappen !== null;
        }
    });
}

function ProductOverview({
    product,
    zones,
    dates,
    totalAmountRappen,
}: {
    product: ParkingaboProductTemplateReservation;
    zones: ParkingaboZoneInfo[];
    dates: DateTime[];
    totalAmountRappen: number;
}) {
    const productZones = zones.filter(zone =>
        product.zoneIds.includes(zone.zoneId),
    );
    const sortedDates = dates.sort(compareDateTime);

    return (
        <WizardFadeInEffect>
            <ParkingaboOverlineList.Body>
                <ParkingaboOverlineList.Item
                    label={
                        productZones.length > 1 ? (
                            <Localized
                                de="Parkings"
                                fr="Parkings"
                                it="Parcheggi"
                                en="Parkings"
                            />
                        ) : (
                            <Localized
                                de="Parking"
                                fr="Parking"
                                it="Parcheggio"
                                en="Parking"
                            />
                        )
                    }
                >
                    {productZones.map(zone => (
                        <Box
                            sx={{
                                textOverflow: 'ellipsis',
                                whiteSpace: 'nowrap',
                                overflow: 'hidden',
                            }}
                            key={zone.zoneId}
                        >
                            {formatZoneName(zone)}
                        </Box>
                    ))}
                </ParkingaboOverlineList.Item>
                <ParkingaboOverlineList.Item
                    label={
                        sortedDates.length === 1 ? (
                            <Localized
                                de="Tag"
                                fr="Jour"
                                it="Giorno"
                                en="Day"
                            />
                        ) : (
                            <Localized
                                de="Tage"
                                fr="Jours"
                                it="Giorni"
                                en="Days"
                            />
                        )
                    }
                >
                    {sortedDates.map(date => (
                        <div key={date.toMillis()}>
                            {Formatter.dayMonthYear(date)}
                            {date.equals(DateTime.now().startOf('day')) ? (
                                <Localized
                                    de=" (ab Kaufzeit)"
                                    fr=" (à partir de l'heure d'achat)"
                                    it=" (dall'ora d'acquisto)"
                                    en=" (as of purchase time)"
                                />
                            ) : (
                                ''
                            )}
                        </div>
                    ))}
                </ParkingaboOverlineList.Item>
                {product.info && (
                    <ParkingaboOverlineList.Item
                        label={
                            <Localized
                                de="Info"
                                fr="Info"
                                it="Info"
                                en="Info"
                            />
                        }
                    >
                        <Localized {...product.info} />
                    </ParkingaboOverlineList.Item>
                )}
                <VehicleType vehicleType={product.vehicleType} />
                <ParkingaboOverlineList.Item
                    label={
                        <Localized
                            de="Preis"
                            fr="Prix"
                            it="Prezzo"
                            en="Price"
                        />
                    }
                >
                    <Typography fontSize={'1.5rem'} fontWeight="bold">
                        {currencyCentsToLocalPrice('de', totalAmountRappen)}
                    </Typography>
                </ParkingaboOverlineList.Item>
            </ParkingaboOverlineList.Body>
        </WizardFadeInEffect>
    );
}

function PurchaseSuccessDialog({
    open,
    onAbort,
}: {
    open: boolean;
    onAbort: () => void;
}) {
    return (
        <FeedbackPopup
            open={open}
            color="success"
            title={
                <Localized
                    de="Bestätigung"
                    fr="Confirmation"
                    it="Conferma"
                    en="Confirmation"
                />
            }
            abortLabel={'OK'}
            onAbort={onAbort}
        >
            <Localized
                de="Das Produkt wurde erfolgreich erworben."
                fr="Le produit a été acheté avec succès."
                it="Il prodotto è stata acquistato con successo."
                en="The product was successfully purchased."
            />
        </FeedbackPopup>
    );
}

function calculateTotalPrice(
    product: ParkingaboProductTemplateReservation,
    dates: DateTime[],
): number | null {
    return dates
        .map(date => {
            const priceConfig = product.dayAndPriceConfig.priceConfig;
            let weekdayRappen: number | null = 0;
            switch (date.weekday) {
                case 1:
                    weekdayRappen = priceConfig.mondayRappen;
                    break;
                case 2:
                    weekdayRappen = priceConfig.tuesdayRappen;
                    break;
                case 3:
                    weekdayRappen = priceConfig.wednesdayRappen;
                    break;
                case 4:
                    weekdayRappen = priceConfig.thursdayRappen;
                    break;
                case 5:
                    weekdayRappen = priceConfig.fridayRappen;
                    break;
                case 6:
                    weekdayRappen = priceConfig.saturdayRappen;
                    break;
                case 7:
                    weekdayRappen = priceConfig.sundayRappen;
                    break;
                default:
                    throw Error('Unknown weekday');
            }
            if (weekdayRappen == null) {
                /*
                 * a price config without rappen (null value) means that this
                 * weekday is disabled. Thus, there should never be a date
                 * with a weekday that was configured as disabled. If this is
                 * still the case we throw.
                 */
                throw Error(`Invalid date ${date}`);
            }
            return weekdayRappen;
        })
        .reduce((a, b) => a + b, 0);
}
