import { useServerSuccessEffect } from 'dg-web-shared/lib/hooks/ServerStateHooks';
import {
    useParkingaboServerFetch,
    useParkingaboServerWrite,
} from '../api/ParkingaboApi';
import { useEffect, useState } from 'react';

const PAYMENT_POLLING_TIMEOUT = 1000;
const PAYMENT_POLLING_CANCEL_TIMEOUT = 10000;

export enum PaymentAttemptState {
    ABORTED = 'ABORTED',
    AUTHORIZED = 'AUTHORIZED',
    INITIALIZED = 'INITIALIZED',
    CREATED = 'CREATED',
    REFUNDED = 'REFUNDED',
    SETTLED = 'SETTLED',
}

export function paymentIsPending(paymentState?: PaymentStatus | null) {
    if (!paymentState) {
        return false;
    }

    return (
        paymentState.state === PaymentAttemptState.CREATED ||
        paymentState.state === PaymentAttemptState.INITIALIZED ||
        paymentState.state === PaymentAttemptState.AUTHORIZED
    );
}

export interface PaymentStatus {
    state: PaymentAttemptState;
}

export function usePaymentStatus(pendingPaymentAttemptId: string) {
    const [timeoutId, setTimeoutId] = useState<number | null>(null);
    const [canCancelTimeoutId, setCanCancelTimeoutId] = useState<number | null>(
        null,
    );
    const [canCancel, setCanCancel] = useState(false);

    useEffect(() => {
        const id = window.setTimeout(() => {
            // after 30 seconds from the first attempt the payment can be canceled
            setCanCancel(true);
        }, PAYMENT_POLLING_CANCEL_TIMEOUT);

        setCanCancelTimeoutId(id);
    }, []);

    const [paymentStatusState, refetchPaymentStatus] = useParkingaboServerFetch<
        PaymentStatus,
        { paymentAttemptId: string }
    >(
        ({ paymentAttemptId }) => ({
            url: `/ui-api/parkingabo/user/self/payment/${paymentAttemptId}/state`,
        }),
        { paymentAttemptId: pendingPaymentAttemptId },
    );
    const [paymentCancelState, cancel] = useParkingaboServerWrite<
        { paymentAttemptId: string },
        { success: boolean }
    >(({ paymentAttemptId }) => ({
        url: `/ui-api/parkingabo/user/self/payment/${paymentAttemptId}/cancel`,
    }));

    useServerSuccessEffect(paymentStatusState, status => {
        // polling
        if (paymentIsPending(status)) {
            const id = window.setTimeout(() => {
                setTimeoutId(null);
                refetchPaymentStatus();
            }, PAYMENT_POLLING_TIMEOUT);

            setTimeoutId(id);
        }
    });

    function cancelPayment() {
        if (canCancelTimeoutId) {
            window.clearTimeout(canCancelTimeoutId);
        }
        if (timeoutId) {
            window.clearTimeout(timeoutId);
        }
        cancel({ paymentAttemptId: pendingPaymentAttemptId });
    }

    return {
        paymentStatusState,
        paymentCancelState,
        canCancel,
        cancelPayment,
    };
}
