import {
    ThemeProvider,
    StyledEngineProvider,
    Theme,
} from '@mui/material/styles';
import { Language } from '../../lib/Localized';
import { ReactNode, useMemo } from 'react';
import { createTheme } from '@mui/material';
import { deDE, frFR, itIT, enUS, Localization } from '@mui/material/locale';
import { AdapterLuxon } from '@mui/x-date-pickers/AdapterLuxon';
import { LocalizationProvider } from '@mui/x-date-pickers';

interface MaterialThemeProviderProps {
    theme: Theme;
    language: Language;
    children: ReactNode;
}

export function MaterialThemeProvider({
    children,
    theme,
    language,
}: MaterialThemeProviderProps) {
    const localizedMuiTheme = useMemo(() => {
        return createTheme(theme, languageToMuiLocale(language));
    }, [theme, language]);

    return (
        <StyledEngineProvider injectFirst>
            <ThemeProvider theme={localizedMuiTheme}>
                <LocalizationProvider
                    adapterLocale={language}
                    dateAdapter={AdapterLuxon}
                >
                    {children}
                </LocalizationProvider>
            </ThemeProvider>
        </StyledEngineProvider>
    );
}

function languageToMuiLocale(language: Language): Localization {
    switch (language) {
        case Language.DE:
            return {
                components: {
                    ...deDE.components,
                    MuiAutocomplete: deCH.components?.MuiAutocomplete,
                    MuiAlert: deCH.components?.MuiAlert,
                },
            };
        case Language.FR:
            return frFR;
        case Language.IT:
            return itIT;
        case Language.EN:
            return enUS;
    }
}

// We want to overwrite the component texts using 'ß'
const deCH: Localization = {
    components: {
        MuiAutocomplete: {
            defaultProps: {
                clearText: 'Leeren',
                closeText: 'Schliessen',
                loadingText: 'Wird geladen…',
                noOptionsText: 'Keine Optionen',
                openText: 'Öffnen',
            },
        },
        MuiAlert: {
            defaultProps: {
                closeText: 'Schliessen',
            },
        },
    },
};
