import { useNavigate } from 'react-router-dom';
import { useParkingaboAuthedPathGeneration } from '../RouteUtils';
import { FeedbackPopup } from '../../components/FeedbackPopup';
import { Localized } from 'dg-web-shared/common/hooks/LanguageProvider';

export function AccountLockedMessageRoute() {
    const navigate = useNavigate();
    const generateAuthedParkingaboPath = useParkingaboAuthedPathGeneration();

    return (
        <FeedbackPopup
            open={true}
            color="error"
            onAbort={() => {
                navigate(generateAuthedParkingaboPath(''));
            }}
            abortLabel={'OK'}
            title={
                <Localized
                    de="Konto gesperrt"
                    fr="Compte bloqué"
                    it="Conto bloccato"
                    en="Account locked"
                />
            }
        >
            <p>
                <Localized
                    de="Der Betreiber hat Ihr Konto gesperrt. Solange sich das Konto in diesem Zustand befindet, können keine neuen Produkte hinzugefügt werden und bestehende Produkte sind suspendiert (ungültig)."
                    fr="L’exploitant a bloqué votre compte; tant que le compte est dans cet état, aucun nouveau produit ne peut être ajouté et les produits existants sont suspendus (non valables)."
                    it="Il gestore ha bloccato il suo conto; fintanto che il conto si trova in questo stato, non è possibile aggiungere nuovi prodotti e quelli evt. già esistenti sono sospesi (non validi)."
                    en="The operator has locked your account; as long as the account is in this state, new products cannot be added and existing products are suspended (invalid)."
                />
            </p>
            <p>
                <Localized
                    de="Für weitere Auskünfte wenden Sie sich bitte direkt an den Betreiber."
                    fr="Pour plus d'informations, veuillez contacter directement l’exploitant."
                    it="Per ulteriori chiarimenti si rivolga direttamente al gestore."
                    en="For further clarification, please contact the operator directly."
                />
            </p>
        </FeedbackPopup>
    );
}
