import { Box, TextField, Typography } from '@mui/material';
import { Outlet, useLocation } from 'react-router-dom';
import { Localized } from 'dg-web-shared/common/hooks/LanguageProvider';
import { useParkingaboServerWrite } from '../../api/ParkingaboApi';
import {
    RequestStatus,
    useNavigateOnSuccess,
} from 'dg-web-shared/lib/hooks/ServerStateHooks';
import { ParkingaboHeader } from '../../components/layout/ParkingaboHeader';
import {
    layoutStyles,
    ParkingaboLayout,
} from '../../components/layout/ParkingaboLayout';
import { LoadingButton } from '@mui/lab';
import { useState } from 'react';

export const RequestPasswordResetRoute: React.FC = () => {
    const { search } = useLocation();
    const params = new URLSearchParams(search);
    const [email, setEmail] = useState(
        decodeURIComponent(params.get('email') || ''),
    );

    const [resetState, requestReset] = useParkingaboServerWrite<
        {
            email: string;
        },
        void
    >(() => ({ url: '/ui-api/parkingabo/password-reminder/send' }));

    useNavigateOnSuccess(resetState, '/request-password-reset/success');

    return (
        <ParkingaboLayout>
            <Box
                component="form"
                onSubmit={(e: React.FormEvent<HTMLFormElement>) => {
                    e.preventDefault();
                    requestReset({ email });
                }}
                sx={{ ...layoutStyles() }}
            >
                <div>
                    <ParkingaboHeader
                        linkLabel={
                            <Localized
                                de="< Zurück"
                                fr="< Back"
                                it="< Indietro"
                                en="< Back"
                            />
                        }
                        to={`/login${
                            email.length > 0
                                ? `?email=${encodeURIComponent(email)}`
                                : ''
                        }`}
                    />
                    <Typography
                        variant="h1"
                        sx={theme => ({
                            marginTop: theme.spacing(3),
                        })}
                    >
                        <Localized
                            de="Password ersetzen"
                            fr="Remplacer le mot de passe"
                            it="Sostituire la password"
                            en="Replace password"
                        />
                    </Typography>
                    <Typography
                        variant="h3"
                        sx={theme => ({
                            marginBottom: theme.spacing(5),
                            marginTop: theme.spacing(2),
                        })}
                    >
                        <Localized
                            de="E-Mail-Addresse eingeben"
                            fr="Saisir l'adresse e-mail"
                            it="Inserire l'indirizzo email"
                            en="Enter email address"
                        />
                    </Typography>
                    <Typography color="primary">
                        <Localized
                            de="Geben Sie Ihre Email-Adresse ein und klicken Sie auf «Senden». Wir senden daraufhin einen Link zum Ändern des Passworts."
                            fr="Entrez votre adresse email et cliquez sur «Envoyer». Nous vous enverrons un lien pour changer votre mot de passe."
                            it="Inserisca il suo indirizzo e-mail e prema «Invia». Le invieremo un link per modificare la password."
                            en="Enter your e-mail address and press «Send». We will send you a link to change your password."
                        />
                    </Typography>
                    <TextField
                        label={
                            <Localized
                                de="E-Mail"
                                fr="E-mail"
                                it="E-mail"
                                en="Email"
                            />
                        }
                        value={email}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                            setEmail(e.target.value);
                        }}
                        fullWidth={true}
                        type="email"
                        margin="normal"
                    />
                </div>

                <LoadingButton
                    variant="contained"
                    color="primary"
                    disabled={email.length <= 0}
                    loading={resetState.status === RequestStatus.PENDING}
                    type="submit"
                    sx={{ width: '50%', marginLeft: 'auto' }}
                >
                    <Localized de="Senden" fr="Envoyer" it="Invia" en="Send" />
                </LoadingButton>
            </Box>

            <Outlet />
        </ParkingaboLayout>
    );
};
