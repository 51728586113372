import { Localized } from 'dg-web-shared/common/hooks/LanguageProvider';
import { FeedbackPopup } from '../../../components/FeedbackPopup';
import { useNavigate } from 'react-router-dom';

export function PaymentAbortedRoute() {
    const navigate = useNavigate();

    return (
        <FeedbackPopup
            open={true}
            color="error"
            onAbort={() => {
                navigate('..');
            }}
            abortLabel={'OK'}
            title={<Localized de="Fehler" fr="Erreur" it="Errore" en="Error" />}
        >
            <Localized
                de="Ihr Zahlungsmittel konnte nicht hinzugefügt werden."
                fr="Votre moyen de paiement n'a pas pu être ajouté."
                it="Non è stato possibile aggiungere il suo mezzo di pagamento."
                en="Your means of payment could not be added."
            />
        </FeedbackPopup>
    );
}
