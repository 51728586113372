import { useParamOrNull } from 'dg-web-shared/lib/ReactRouterHelpers.ts';
import { useParkingaboServerFetch } from '../../api/ParkingaboApi.tsx';
import {
    LoadingSpinnerPresets,
    PresetLoadingSpinner,
} from 'dg-web-shared/common/components/material-ui/PresetLoadingSpinner.tsx';
import { useNavigate } from 'react-router-dom';
import {
    useServerErrorEffect,
    useServerSuccessEffect,
} from 'dg-web-shared/lib/hooks/ServerStateHooks.ts';
import { useState } from 'react';
import { useParkingaboAuthedPathGeneration } from '../RouteUtils.tsx';
import { FeedbackPopup } from '../../components/FeedbackPopup.tsx';
import { Localized } from 'dg-web-shared/common/hooks/LanguageProvider.tsx';

enum ValidationState {
    LOADING = 'LOADING',
    REDIRECT_TO_LOGIN_ONBOARDING_COMPLETE = 'REDIRECT_TO_LOGIN_ONBOARDING_COMPLETE',
    REDIRECT_TO_LOGIN_ONBOARDING_PENDING = 'REDIRECT_TO_LOGIN_ONBOARDING_PENDING',
    BADGE_NOT_FOUND = 'BADGE_NOT_FOUND',
    WRONG_CUSTOMER = 'WRONG_CUSTOMER',
    GENERIC_ERROR = 'GENERIC_ERROR',
}

export function usePublicBadgeValidationState(badgeLabelNr: string | null) {
    const [badgeValidationState] = useParkingaboServerFetch<
        { isOnboardingComplete: boolean; hasLogin: boolean },
        { badgeLabelNr: string },
        { reason: ValidationErrorReason.BADGE_NOT_FOUND }
    >(
        ({ badgeLabelNr }) => ({
            url: `/ui-api/parkingabo/badge/${badgeLabelNr}/validation`,
        }),
        badgeLabelNr ? { badgeLabelNr: badgeLabelNr } : null,
    );

    return [badgeValidationState];
}

export function PublicParkingaboBadgeValidationRoute() {
    const badgeLabelNr = useParamOrNull('badgeLabelNr');
    const navigate = useNavigate();
    const [validationState, setValidationState] = useState<ValidationState>(
        ValidationState.LOADING,
    );

    const [badgeValidationState] = usePublicBadgeValidationState(badgeLabelNr);

    useServerSuccessEffect(badgeValidationState, data => {
        if (data.isOnboardingComplete) {
            setValidationState(
                ValidationState.REDIRECT_TO_LOGIN_ONBOARDING_COMPLETE,
            );
        } else if (data.hasLogin) {
            setValidationState(
                ValidationState.REDIRECT_TO_LOGIN_ONBOARDING_PENDING,
            );
        } else {
            navigate(`/register-with-badge/${badgeLabelNr}`);
        }
    });

    useServerErrorEffect(badgeValidationState, (_, data) => {
        if (data?.reason === ValidationErrorReason.BADGE_NOT_FOUND) {
            setValidationState(ValidationState.BADGE_NOT_FOUND);
        } else {
            setValidationState(ValidationState.GENERIC_ERROR);
        }
    });

    if (badgeLabelNr === null) {
        navigate('/login');
    }

    switch (validationState) {
        case ValidationState.LOADING:
            return (
                <PresetLoadingSpinner
                    preset={LoadingSpinnerPresets.FillAllSpaceAndCenter}
                />
            );
        case ValidationState.REDIRECT_TO_LOGIN_ONBOARDING_COMPLETE:
        case ValidationState.REDIRECT_TO_LOGIN_ONBOARDING_PENDING:
            return (
                <RedirectToLogin
                    onConfirm={() => navigate('/login')}
                    validationState={validationState}
                />
            );
        case ValidationState.BADGE_NOT_FOUND:
            return <BadgeNotFoundPopup />;
        case ValidationState.GENERIC_ERROR:
            return <GenericErrorPopup />;
        default:
            return null;
    }
}

export function AuthedParkingaboBadgeValidationRoute() {
    const badgeLabelNr = useParamOrNull('badgeLabelNr');
    const navigate = useNavigate();
    const generateAuthedParkingaboPath = useParkingaboAuthedPathGeneration();
    const [validationState, setValidationState] = useState<ValidationState>(
        ValidationState.LOADING,
    );

    const [badgeValidationState] = useParkingaboServerFetch<
        null,
        { badgeLabelNr: string },
        { reason: ValidationErrorReason }
    >(
        ({ badgeLabelNr }) => ({
            url: `/ui-api/parkingabo/user/badge/${badgeLabelNr}/validation`,
        }),
        badgeLabelNr ? { badgeLabelNr: badgeLabelNr } : null,
    );

    useServerSuccessEffect(badgeValidationState, () => {
        navigate(generateAuthedParkingaboPath('/'));
    });

    useServerErrorEffect(badgeValidationState, (_, data) => {
        if (data === null) {
            setValidationState(ValidationState.GENERIC_ERROR);
        } else {
            switch (data.reason) {
                case ValidationErrorReason.BADGE_NOT_FOUND:
                    setValidationState(ValidationState.BADGE_NOT_FOUND);
                    break;
                case ValidationErrorReason.WRONG_CUSTOMER:
                    setValidationState(ValidationState.WRONG_CUSTOMER);
                    break;
            }
        }
    });

    if (badgeLabelNr === null) {
        navigate(generateAuthedParkingaboPath('/'));
    }

    switch (validationState) {
        case ValidationState.LOADING:
            return (
                <PresetLoadingSpinner
                    preset={LoadingSpinnerPresets.FillAllSpaceAndCenter}
                />
            );
        case ValidationState.WRONG_CUSTOMER:
            return <ErrorPopupByValidationErrorReason />;
        case ValidationState.BADGE_NOT_FOUND:
            return <BadgeNotFoundPopup />;
        case ValidationState.GENERIC_ERROR:
            return <GenericErrorPopup />;
        default:
            return null;
    }
}

export function RedirectToLogin({
    onConfirm,
    validationState,
}: {
    onConfirm: () => void;
    validationState:
        | ValidationState.REDIRECT_TO_LOGIN_ONBOARDING_COMPLETE
        | ValidationState.REDIRECT_TO_LOGIN_ONBOARDING_PENDING;
}) {
    switch (validationState) {
        case ValidationState.REDIRECT_TO_LOGIN_ONBOARDING_COMPLETE:
            return (
                <FeedbackPopup
                    open={true}
                    color="success"
                    onConfirm={onConfirm}
                    abortLabel={'OK'}
                    title={
                        <Localized
                            de="Gültiger Badge"
                            fr="Badge valuable"
                            it="Badge valido"
                            en="Valid badge"
                        />
                    }
                >
                    <p>
                        <Localized
                            de="Dieser Badge ist im Parkingabo-System korrekt konfiguriert."
                            fr="Ce badge est correctement configuré dans le système Parkingabo."
                            it="Questo badge è configurato correttamente nel sistema Parkingabo."
                            en="This badge is correctly configured in the Parkingabo system."
                        />
                    </p>
                    <p>
                        <Localized
                            de="Wenn Sie Ihre Konfiguration überprüfen/ändern oder Ihre Transaktionen einsehen möchten, melden Sie sich mit Ihren Zugangsdaten bei Ihrem Parkingabo-Konto an."
                            fr="Si vous souhaitez vérifier/modifier votre configuration ou consulter vos transactions, connectez-vous à votre compte Parkingabo avec vos identifiants."
                            it="Se vuole verificare/modificare la configurazione o consultare le transazioni, acceda al tuo conto Parkingabo con le sue credenziali."
                            en="If you want to check/change your configuration or consult your transactions, log in to your Parkingabo account with your credentials."
                        />
                    </p>
                </FeedbackPopup>
            );
        case ValidationState.REDIRECT_TO_LOGIN_ONBOARDING_PENDING:
            return (
                <FeedbackPopup
                    open={true}
                    color="warning"
                    onConfirm={onConfirm}
                    abortLabel={'OK'}
                    title={
                        <Localized
                            de="Konfiguration nicht vollständig"
                            fr="Configuration incomplet"
                            it="Configurazione non completa"
                            en="Configuration not complete"
                        />
                    }
                >
                    <p>
                        <Localized
                            de="Um diesen Badge nutzen zu können, müssen Sie die Konfiguration abschließen, indem Sie sich mit Ihren Zugangsdaten bei Ihrem Parkingabo-Konto anmelden und den Anweisungen folgen."
                            fr="Pour utiliser ce badge, vous devez compléter votre configuration en vous connectant à votre compte Parkingabo avec vos identifiants et en suivant les instructions."
                            it="Per poter utilizzare questo badge, deve completare la configurazione accedendo al suo conto con le sue credenziali e seguendo le istruzioni."
                            en="In order to use this badge, you must complete the configuration by logging into your Parkingabo account using your credentials and following the instructions."
                        />
                    </p>
                </FeedbackPopup>
            );
    }
}

export function ErrorPopupByValidationErrorReason() {
    return (
        <FeedbackPopup
            open={true}
            color="error"
            title={
                <Localized
                    de="Bereits registriert"
                    fr="Déjà enregistré"
                    it="Già registrato"
                    en="Already registered"
                />
            }
        >
            <p>
                <Localized
                    de="Sie sind bereits bei Parkingabo registriert und können sich daher nicht erneut mit diesem Badge registrieren."
                    fr="Vous êtes déjà enregistré dans Parkingabo et ne pouvez donc pas vous enregistrer à nouveau avec ce badge."
                    it="Siete già registrato in Parkingabo e quindi non è possibile effettuare una nuova registrazione con questo badge."
                    en="You are already registered in Parkingabo and therefore cannot register again with this badge."
                />
            </p>
        </FeedbackPopup>
    );
}

export function BadgeNotFoundPopup() {
    return (
        <FeedbackPopup
            open={true}
            color="error"
            title={
                <Localized
                    de="Badge nicht gefunden"
                    fr="Badge introuvable"
                    it="Badge non trovato"
                    en="Badge not found"
                />
            }
        >
            <p>
                <Localized
                    de="Dieser Badge ist entweder im Parkingabo-System nicht konfiguriert oder kann nicht Online abgefragt werden."
                    fr="Ce badge n'est pas configuré dans le système Parkingabo ou ne peut pas être consulté en ligne."
                    it="Questo badge non è configurato nel sistema Parkingabo oppure non può essere consultato online."
                    en="This badge is either not configured in the Parkingpay system or cannot be accessed online."
                />
            </p>
        </FeedbackPopup>
    );
}

export function GenericErrorPopup() {
    return (
        <FeedbackPopup
            open={true}
            color="error"
            title={
                <Localized
                    de="Es ist ein Fehler aufgetreten."
                    fr="Une erreur est survenue."
                    it="Si è verificato un errore."
                    en="An error has occurred."
                />
            }
        >
            <p>
                <Localized
                    de="Bitte versuchen Sie es in ein paar Minuten erneut."
                    fr="Veuillez réessayer dans quelques minutes."
                    it="Voglia riprovare tra qualche minuto."
                    en="Please try again in a few minutes."
                />
            </p>
        </FeedbackPopup>
    );
}

enum ValidationErrorReason {
    BADGE_NOT_FOUND = 'BADGE_NOT_FOUND',
    WRONG_CUSTOMER = 'WRONG_CUSTOMER',
}
