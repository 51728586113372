import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Typography } from '@mui/material';
import { DateTime } from 'luxon';
import {
    ParkingaboProductListItem,
    ParkingaboProductType,
} from '../../../shared/ParkingaboProductModels';
import { useParkingaboServerWrite } from '../../../api/ParkingaboApi';
import { ParkingaboButton } from '../../../components/layout/ParkingaboButton';
import {
    RequestMethod,
    RequestStatus,
    useServerSuccessEffect,
} from 'dg-web-shared/lib/hooks/ServerStateHooks';
import { FeedbackPopup } from '../../../components/FeedbackPopup';
import { BackendRequestErrorMessage } from 'dg-web-shared/common/components/material-ui/BackendRequestErrorMessage';
import { Localized } from 'dg-web-shared/common/hooks/LanguageProvider';
import { ProductDetailBody, ProductOverviewList } from './ProductsDetailOutlet';

interface ReservationCancellationPayload {
    contractId: number;
}

export function ProductDetailReservation({
    product,
    refetchProducts,
}: {
    product: ParkingaboProductListItem;
    refetchProducts: () => void;
}) {
    const navigate = useNavigate();

    const [cancelConfirmDialogOpen, setCancelConfirmDialogOpen] =
        useState(false);
    const [cancelSuccessDialogOpen, setCancelSuccessDialogOpen] =
        useState(false);

    const [cancellationState, cancelReservation] = useParkingaboServerWrite<
        ReservationCancellationPayload,
        never
    >(({ contractId }) => ({
        url: `/ui-api/parkingabo/user/self/product/${contractId}`,
        method: RequestMethod.DELETE,
    }));

    useServerSuccessEffect(cancellationState, () => {
        setCancelSuccessDialogOpen(true);
    });

    const validFrom = DateTime.fromISO(product.validFrom);
    const now = DateTime.now();
    const showCancelButton =
        now < validFrom && product.type === ParkingaboProductType.RESERVATION;

    if (product.type !== ParkingaboProductType.RESERVATION) {
        throw new Error('product type must be reservation');
    }

    return (
        <ProductDetailBody>
            <BackendRequestErrorMessage requestState={cancellationState} />
            <ProductOverviewList product={product} />
            {showCancelButton && (
                <ParkingaboButton
                    variant="outlined"
                    loading={cancellationState.status === RequestStatus.PENDING}
                    onClick={() => setCancelConfirmDialogOpen(true)}
                >
                    <Localized
                        de="Reservierung löschen"
                        fr="Supprimer la réservation"
                        it="Cancella riservazione"
                        en="Delete reservation"
                    />
                </ParkingaboButton>
            )}

            <CancellationConfirmDialog
                open={cancelConfirmDialogOpen}
                onAbort={() => setCancelConfirmDialogOpen(false)}
                onConfirm={() => {
                    cancelReservation({
                        contractId: product.contractId,
                    });
                    setCancelConfirmDialogOpen(false);
                }}
            />
            <CancelSuccessDialog
                open={cancelSuccessDialogOpen}
                onClick={() => {
                    refetchProducts();
                    setCancelSuccessDialogOpen(false);
                    navigate('..');
                }}
            />
        </ProductDetailBody>
    );
}

function CancellationConfirmDialog({
    open,
    onAbort,
    onConfirm,
}: {
    open: boolean;
    onAbort: () => void;
    onConfirm: () => void;
}) {
    return (
        <FeedbackPopup
            open={open}
            color="warning"
            title={
                <Localized
                    de="Reservierung löschen"
                    fr="Supprimer la réservation"
                    it="Cancella riservazione"
                    en="Delete reservation"
                />
            }
            onAbort={onAbort}
            onConfirm={onConfirm}
            confirmLabel={
                <Localized
                    de="Löschen"
                    fr="Supprimer"
                    it="Elimina"
                    en="Delete"
                />
            }
        >
            <Typography component="p" sx={{ paddingBottom: 1 }}>
                <Localized
                    de="Wenn Sie Ihre Reservierung jetzt löschen, "
                    fr="Si vous supprimez votre réservation maintenant, "
                    it="Cancellando adesso la riservazione "
                    en="If you delete your reservation now,  "
                />
                <b>
                    <Localized
                        de="werden keine Kosten"
                        fr="aucun coût ne sera débité"
                        it="non verrà addebitato alcuno costo"
                        en="no costs will be charged"
                    />
                </b>
                <Localized
                    de=" auf Ihrem Parkingabo Konto "
                    fr=" sur votre compte Parkingabo."
                    it=" sul suo conto Parkingabo."
                    en=" to your Parkingabo account."
                />
                <b>
                    <Localized de="belastet." fr="" it="" en="" />
                </b>
            </Typography>
            <Typography>
                <Localized
                    de="Möchten Sie diese Reservierung wirklich löschen?"
                    fr="Voulez-vous vraiment supprimer cette réservation?"
                    it="Vuole veramente cancellare questa riservazione?"
                    en="Do you really want to delete this reservation?"
                />
            </Typography>
        </FeedbackPopup>
    );
}

export function CancelSuccessDialog({
    open,
    onClick,
}: {
    open: boolean;
    onClick: () => void;
}) {
    return (
        <FeedbackPopup
            open={open}
            color="success"
            title={
                <Localized
                    de="Bestätigung"
                    fr="Confirmation"
                    it="Conferma"
                    en="Confirmation"
                />
            }
            abortLabel={'OK'}
            onAbort={onClick}
        >
            <Localized
                de="Die Reservierung wurde erfolgreich gelöscht."
                fr="La réservation a été supprimée avec succès."
                it="La riservazione è stata cancellata con successo."
                en="The reservation was successfully deleted."
            />
        </FeedbackPopup>
    );
}
