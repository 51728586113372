import { useLocation } from 'react-router';
import { Localized } from 'dg-web-shared/common/hooks/LanguageProvider';
import { FeedbackPopup } from '../../components/FeedbackPopup';
import { useNavigate } from 'react-router-dom';
import { envIsProduction } from 'dg-web-shared/lib/Environment';

export function RegistrationConfirmationRoute() {
    const navigate = useNavigate();
    const confirmUrl = new URLSearchParams(useLocation().search).get(
        'confirmUrl',
    );

    return (
        <FeedbackPopup
            open={true}
            color="success"
            onAbort={() => {
                navigate('/login');
            }}
            abortLabel={
                <Localized de="Schliessen" fr="Fermer" it="Chiudi" en="Close" />
            }
            title={
                <Localized
                    de="E-Mail bestätigen"
                    fr="Confirmation e-mail"
                    it="Confermare e-mail"
                    en="Confirm e-mail"
                />
            }
        >
            <Localized
                de="Wir haben Ihnen eine E-Mail mit einem Bestätigungslink geschickt. Bitte klicken Sie auf den Link, um Ihre E-Mail zu bestätigen und Ihr Konto zu aktivieren."
                fr="Nous vous avons envoyé un e-mail avec un lien de confirmation. Veuillez cliquer sur le lien pour confirmer votre e-mail et activer votre compte."
                it="Le abbiamo inviato un e-mail con il link di conferma. Clicchi sul link per confermare il suo e-mail e attivare il conto."
                en="We have sent you an e-mail with a confirmation link. Please click on the link to confirm your e-mail and activate your account."
            />
            {!envIsProduction() && confirmUrl && (
                <>
                    <br />
                    <a href={confirmUrl}>
                        <Localized
                            de={'Jetzt aktivieren'}
                            fr={'Activer maintenant'}
                            it={'Attiva adesso'}
                            en={'Activate now'}
                        />
                    </a>
                </>
            )}
        </FeedbackPopup>
    );
}
