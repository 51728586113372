import { Link } from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';
import { ComponentProps } from 'react';

export function ParkingaboLink(
    props: ComponentProps<typeof RouterLink> & ComponentProps<typeof Link>,
) {
    const { underline, ...reducedProps } = props;
    return (
        <Link
            component={RouterLink}
            underline={underline ?? 'always'}
            {...reducedProps}
        />
    );
}
