import { useEffect, useState } from 'react';

export const enum WindowOrientation {
    landscape = 'landscape',
    portrait = 'portrait',
}

export function OrientationConditional({
    visibleOrientation,
    children,
}: {
    visibleOrientation: WindowOrientation;
    children: React.ReactNode;
}) {
    const [orientation, setOrientation] = useState(
        getCurrentWindowOrientation(),
    );
    useEffect(() => {
        function eventListener() {
            setOrientation(getCurrentWindowOrientation());
        }
        window.addEventListener('orientationchange', eventListener);
        return () => {
            window.removeEventListener('orientationchange', eventListener);
        };
    }, []);

    if (orientation === visibleOrientation) {
        return <>{children}</>;
    }
    return null;
}

export function LandscapeOverlayIcon() {
    return (
        <svg
            width="64px"
            height="52px"
            viewBox="100 0 64 52"
            stroke="none"
            strokeWidth="1"
            fill="currentColor"
            fillRule="nonzero"
        >
            <path d="M137.57088,5.02236852 C143.854372,5.40383458 148.832417,10.6205839 148.832417,17.000018 C148.832417,17.5523027 148.384702,18.000018 147.832417,18.000018 C147.280133,18.000018 146.832417,17.5523027 146.832417,17.000018 C146.832417,11.6043777 142.559133,7.20681645 137.212333,7.00710165 L139.482024,9.91217395 C139.822045,10.3473803 139.744881,10.9758257 139.309675,11.3158462 C138.874468,11.6558666 138.246023,11.5787032 137.906003,11.1434969 L134.212034,6.41543238 C133.872013,5.98022605 133.949177,5.35178059 134.384383,5.01176015 C134.424197,4.98065398 134.465628,4.9530393 134.508308,4.92887083 L139.084817,1.22288717 C139.514023,0.875323112 140.143719,0.941506875 140.491283,1.37071274 C140.838847,1.7999186 140.772664,2.42961504 140.343458,2.77717909 L137.57088,5.02236852 Z M119,45 L105,45 C102.238576,45 100,42.7614237 100,40 L100,5 C100,2.23857625 102.238576,5.55111512e-16 105,0 L125,0 C127.761424,-4.4408921e-16 130,2.23857625 130,5 L130,22 L159,22 C161.761424,22 164,24.2385763 164,27 L164,47 C164,49.7614237 161.761424,52 159,52 L124,52 C121.238576,52 119,49.7614237 119,47 L119,45 Z M128,22 L128,5 C128,3.34314575 126.656854,2 125,2 L105,2 C103.343146,2 102,3.34314575 102,5 L102,40 C102,41.6568542 103.343146,43 105,43 L119,43 L119,27 C119,24.2385763 121.238576,22 124,22 L128,22 Z M162,27 C162,25.3431458 160.656854,24 159,24 L124,24 C122.343146,24 121,25.3431458 121,27 L121,47 C121,48.6568542 122.343146,50 124,50 L159,50 C160.656854,50 162,48.6568542 162,47 L162,27 Z" />
        </svg>
    );
}

function getCurrentWindowOrientation(): WindowOrientation {
    switch (window.orientation) {
        case -90:
        case 90:
            return WindowOrientation.landscape;
        default:
            return WindowOrientation.portrait;
    }
}
