import { Box } from '@mui/material';

export function ParkingaboLayout({
    children,
    style,
}: {
    children?: React.ReactNode | React.ReactNodeArray;
    style?: React.CSSProperties;
}) {
    return (
        <Box
            sx={theme => ({
                ...layoutStyles(),
                padding: theme.spacing(2),
                ...style,
            })}
        >
            {children}
        </Box>
    );
}

export function layoutStyles() {
    return {
        display: 'flex',
        justifyContent: 'space-between',
        flexDirection: 'column',
        flexGrow: 1,
        overflowY: 'auto',
        height: '100%',
    };
}
