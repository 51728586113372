import { Box, Typography, useTheme } from '@mui/material';

import { languageOptions } from 'dg-web-shared/common/components/material-ui/react-hook-form-fields/CommonOptions';
import { ReactHookFormRadioSelect } from 'dg-web-shared/common/components/material-ui/react-hook-form-fields/ReactHookFormSelect';
import { ReactHookFormTextField } from 'dg-web-shared/common/components/material-ui/react-hook-form-fields/ReactHookFormTextField';
import {
    Localized,
    useLanguage,
} from 'dg-web-shared/common/hooks/LanguageProvider';
import {
    setNullInitialValuesToEmptyStrings,
    useEasyForm,
} from 'dg-web-shared/common/utils/FormHooksUtils';
import {
    RequestStatus,
    ServerRequestState,
} from 'dg-web-shared/lib/hooks/ServerStateHooks';
import { Language } from 'dg-web-shared/lib/Localized';
import { CancelSave } from '../layout/CancelSave';
import { SelectableList } from '../SelectableList';
import { Control, Controller } from 'react-hook-form';
import { ValidationData } from 'dg-web-shared/lib/forms/FormValidationHelpers';
import { GenericFormSubmitError } from '../../shared/GenericFormSubmitError.tsx';

enum Fields {
    FirstName = 'firstName',
    LastName = 'lastName',
    Language = 'language',
}

export namespace UserDataForm {
    export interface UserData {
        [Fields.FirstName]: string | null;
        [Fields.LastName]: string | null;
        [Fields.Language]: Language;
    }

    export interface UserDataFormProps {
        requestState: ServerRequestState<never, ValidationData>;
        initialValues?: UserData;
        submit: (formData: UserData) => void;
        noCancelSaveButton?: boolean;
        onCancel: () => void;
    }

    export function UserBaseForm({
        requestState,
        submit,
        initialValues,
        render,
        noCancelSaveButton,
        onCancel,
    }: UserDataFormProps & {
        render: (
            control: Control<UserData>,
            requestState: ServerRequestState<never, ValidationData>,
            submit: () => void,
        ) => JSX.Element;
    }) {
        const { language } = useLanguage();

        const {
            formState: { isDirty },
            formInfo: { control, handleSubmit, reset },
            genericSubmitError,
        } = useEasyForm(undefined, requestState, language, {
            defaultValues: initialValues
                ? ({
                      ...setNullInitialValuesToEmptyStrings(initialValues),
                  } as UserData)
                : {
                      [Fields.FirstName]: '',
                      [Fields.LastName]: '',
                      [Fields.Language]: language,
                  },
        });

        return (
            <form
                style={{
                    flexGrow: 1,
                    display: 'flex',
                    justifyContent: 'space-between',
                    flexDirection: 'column',
                }}
                onSubmit={handleSubmit(submit)}
            >
                <Box>
                    {render(control, requestState, handleSubmit(submit))}
                    <GenericFormSubmitError
                        submitState={requestState}
                        error={genericSubmitError}
                    />
                </Box>
                {!noCancelSaveButton && (
                    <CancelSave
                        onCancel={() => {
                            reset();
                            onCancel();
                        }}
                        saveDisabled={!isDirty}
                        requestState={requestState}
                    />
                )}
            </form>
        );
    }

    export function NameField({ control }: { control: Control<UserData> }) {
        const theme = useTheme();
        return (
            <>
                <ReactHookFormTextField
                    style={{
                        marginBottom: theme.spacing(3),
                    }}
                    control={control}
                    name={Fields.FirstName}
                    required={true}
                    label={
                        <Localized
                            de="Vorname"
                            fr="Prénom"
                            it="Nome"
                            en="Firstname"
                        />
                    }
                />
                <ReactHookFormTextField
                    style={{
                        marginBottom: theme.spacing(3),
                    }}
                    control={control}
                    name={Fields.LastName}
                    required={true}
                    label={
                        <Localized
                            de="Nachname"
                            fr="Nom"
                            it="Cognome"
                            en="Lastname"
                        />
                    }
                />
            </>
        );
    }

    export function LanguageFieldWithRadios({
        control,
    }: {
        control: Control<UserData>;
    }) {
        const theme = useTheme();
        return (
            <>
                <ReactHookFormRadioSelect
                    style={{
                        marginBottom: theme.spacing(3),
                    }}
                    control={control}
                    name={Fields.Language}
                    label={
                        <Localized
                            de="Korrespondenzsprache"
                            fr="Langue de correspondance"
                            it="Lingua di corrispondenza"
                            en="Correspondence language"
                        />
                    }
                    options={languageOptions}
                />
            </>
        );
    }

    export function LanguageField({
        control,
        requestStatus,
        submit,
    }: {
        control: Control<UserData>;
        requestStatus: RequestStatus;
        submit: () => void;
    }) {
        return (
            <Controller
                name={Fields.Language}
                control={control}
                render={({ field: { onChange, value } }) => (
                    <SelectableList
                        selectionItems={languageOptions.map(language => ({
                            id: language.value,
                            content: (
                                <Typography fontWeight="bold">
                                    {language.display}
                                </Typography>
                            ),
                        }))}
                        initialSelection={[value]}
                        onChange={selectedIds => {
                            onChange(selectedIds[0] ?? Language.DE);
                            submit();
                        }}
                        loading={requestStatus === RequestStatus.PENDING}
                        preventDeselect
                    />
                )}
            />
        );
    }
}
