import { Box } from '@mui/material';
import { SvgIcon } from 'dg-web-shared/ui/icons/SvgIcon';

export function PaymentIcon({ icon }: { icon: string }) {
    return (
        <Box
            sx={theme => ({
                width: 56,
                height: 56,
                marginLeft: theme.spacing(1),
            })}
        >
            <SvgIcon icon={icon} width={48} height={48} />
        </Box>
    );
}
