import {
    useParkingaboServerFetch,
    useParkingaboServerWrite,
} from './ParkingaboApi';
import {
    RequestMethod,
    ServerWriteState,
    useServerSuccessEffect,
} from 'dg-web-shared/lib/hooks/ServerStateHooks';
import { isPWA } from 'dg-web-shared/lib/utils/PwaUtils';
import { ValidationData } from 'dg-web-shared/lib/forms/FormValidationHelpers';

export interface SessionValidity {
    expiryDate: string;
    csrfToken: string;
    loginId: string | null;
    subdomain: string | null;
    allowsSignup: boolean | null;
    refreshToken?: string;
}

export const REFRESH_TOKEN_LOCALSTORAGE_KEY = 'parkingabo_login_token';

export function useCheckAndRefreshSession() {
    return useParkingaboServerFetch<SessionValidity>(
        () => ({
            url: '/ui-api/parkingabo/session/check-and-refresh',
            method: RequestMethod.POST,
        }),
        {},
        true,
    );
}

interface LoginPayload {
    email: string;
    password: string;
    createRefreshToken: boolean;
    rememberMe: boolean;
}

export function useLoginRequest(): ServerWriteState<
    SessionValidity,
    ValidationData,
    LoginPayload
> {
    const [loginState, sendLogin, reset] = useParkingaboServerWrite<
        LoginPayload,
        SessionValidity
    >(
        () => ({
            url: '/ui-api/parkingabo/session/login',
        }),
        true,
    );

    useServerSuccessEffect(loginState, data => {
        if (isPWA() && data.refreshToken) {
            localStorage.setItem(
                REFRESH_TOKEN_LOCALSTORAGE_KEY,
                data.refreshToken,
            );
        }

        // we're out of the router
        history.replaceState({}, document.title, '/');
    });

    return [loginState, sendLogin, reset];
}

export function useSession() {
    return useParkingaboServerWrite<
        {
            refreshToken?: string;
        },
        SessionValidity
    >(() => ({
        url: '/ui-api/parkingabo/session',
    }));
}
