import { Message } from 'dg-web-shared/lib/Localized';
import { VehicleLicensePlateType } from 'dg-web-shared/common/models/Vehicle';

export interface ParkingaboProductListItem {
    contractId: number;
    name: Message;
    remark: string;
    info: Message | null;
    zones: ParkingaboZoneInfo[];
    validFrom: string;
    validTo: string | null;
    priceRappen: number;
    type: ParkingaboProductType;
    state: ParkingaboProductState;
    vehicleType: VehicleLicensePlateType;
}

export interface ParkingaboZoneInfo {
    zoneId: number;
    name: string;
    city: string;
    zipCode: string;
}

export interface ParkingaboCityInfo {
    zipCode: string;
    city: string;
}

export const enum ParkingaboProductType {
    RESERVATION = 'RESERVATION',
    GUEST_RESERVATION = 'GUEST_RESERVATION',
    SUBSCRIPTION = 'SUBSCRIPTION',
    PERMIT_UNTIL_REVOCATION = 'PERMIT_UNTIL_REVOCATION',
}

export const enum ParkingaboProductState {
    EXPIRED = 'EXPIRED',
    ACTIVE = 'ACTIVE',
    FUTURE = 'FUTURE',
}
