import { Localized } from 'dg-web-shared/common/hooks/LanguageProvider';
import { FeedbackPopup } from '../../components/FeedbackPopup';
import { useNavigate } from 'react-router-dom';

export function EmailConfirmedModalRoute({
    isRegistration,
}: {
    isRegistration?: boolean;
}) {
    const navigate = useNavigate();
    return (
        <FeedbackPopup
            open={true}
            color="success"
            onConfirm={() => navigate('..')}
            title={
                <Localized
                    de="Bestätigung"
                    fr="Confirmation"
                    it="Conferma"
                    en="Confirmation"
                />
            }
        >
            {isRegistration ? (
                <Localized
                    de="Ihre E-Mail-Adresse wurde erfolgreich bestätigt."
                    fr="Votre adresse e-mail a été confirmée avec succès."
                    it="Il suo indirizzo e-mail è stato confermato con successo."
                    en="Your e-mail address has been successfully confirmed."
                />
            ) : (
                <Localized
                    de="Ihre neue E-Mail-Adresse wurde erfolgreich bestätigt und ist ab sofort gültig."
                    fr="Votre nouvelle adresse e-mail a été confirmée avec succès et est valable dès maintenant."
                    it="Il suo nuovo indirizzo e-mail è stato confermato con successo ed è valido da subito."
                    en="Your new e-mail address has been successfully confirmed and is now valid."
                />
            )}
        </FeedbackPopup>
    );
}
