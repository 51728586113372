import { Box, Link, Stack, Typography } from '@mui/material';
import { ParkingaboLink } from '../ParkinaboLink';
import {
    Localized,
    useLanguage,
} from 'dg-web-shared/common/hooks/LanguageProvider';
import { LanguageSelector } from '../LanguageSelector';
import { Legal } from 'dg-web-shared/lib/legal/legal_module_imports';
import { ParkingaboUser } from '../../shared/ParkingaboModels';
import { ReactNode } from 'react';

export function ParkingaboFooter({
    internal,
    user,
}: {
    internal?: boolean;
    user?: ParkingaboUser;
}) {
    const { language } = useLanguage();
    // hack -> information should be provided by the backend at some point
    const mailto =
        user?.tenantId === 361 ? 'mailto:logistics@cardcenter.ch' : undefined;
    return (
        <Box
            sx={theme => ({
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                background: theme.palette.primary.main,
                color: theme.palette.primary.contrastText,
                padding: `${theme.spacing(1)} ${theme.spacing(2)}`,
                height: 54,
            })}
        >
            <Stack
                direction="row"
                justifyContent="flex-start"
                alignItems="stretch"
                spacing={1.5}
            >
                {internal && (
                    <FooterLink to={''} mailto={mailto}>
                        <Localized de="Hilfe" fr="Aide" it="Aiuto" en="Help" />
                    </FooterLink>
                )}
                <FooterLink
                    to={Legal.deriveParkingaboPrivacy()[language]}
                    target={'_blank'}
                >
                    <Localized
                        de="Privacy"
                        fr="Privacy"
                        it="Privacy"
                        en="Privacy"
                    />
                </FooterLink>
                <FooterLink
                    to={Legal.deriveParkingaboTos()[language]}
                    target={'_blank'}
                >
                    <Localized de="AGB" fr="CGV" it="CG" en="TS" />
                </FooterLink>
            </Stack>
            <LanguageSelector />
        </Box>
    );
}

function FooterLink({
    to,
    mailto,
    children,
    target,
}: {
    to: string;
    mailto?: string;
    children: ReactNode;
    target?: string;
}) {
    if (mailto) {
        return (
            <Typography
                variant="subtitle2"
                sx={theme => ({
                    color: theme.palette.primary.contrastText,
                })}
                component={Link}
                href={mailto}
                target={target}
            >
                {children}
            </Typography>
        );
    }
    return (
        <Typography
            variant="subtitle2"
            sx={theme => ({
                color: theme.palette.primary.contrastText,
            })}
            component={ParkingaboLink}
            to={to}
            target={target}
        >
            {children}
        </Typography>
    );
}
